/** @format */
import Instructions from './Instructions';
import AssessmentComplete from './AssessmentComplete';
import watsonGlaserData from '../../redux/data/talentLensData';
import Radio from '../Radio';

import { useState, useEffect } from 'react';

const WatsonGlaserDetails = ({ step, title }) => {
	let [data, setData] = useState(watsonGlaserData.inferItems[0]);
	useEffect(() => {
		if (step === 1) {
			setData(watsonGlaserData.inferItems[0]);
		} else if (step === 2) {
			setData(watsonGlaserData.recognizeItems[0]);
		} else if (step === 3) {
			setData(watsonGlaserData.deduceItems[0]);
		} else if (step === 4) {
			setData(watsonGlaserData.interpretItems[0]);
		} else if (step === 5) {
			setData(watsonGlaserData.evaluateArguments[0]);
		}
	}, [step]);

	return (
		<>
			{step == 0 ? (
				<Instructions />
			) : step > 0 && step < 6 ? (
				<section className='assessment-question'>
					<h1>
						{step}. {title}
					</h1>

					<div className='item-details'>
						<span>Question: 1 of 16</span>
					</div>

					<h2>Statement</h2>
					<p>{data.statement}</p>

					<h2>Proposed inference</h2>
					<p>{data.proposed}</p>

					<Radio options={data.choices} />
				</section>
			) : (
				<AssessmentComplete />
			)}
		</>
	);
};

export default WatsonGlaserDetails;

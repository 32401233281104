/** @format */

import Button from './Button';
import Checkbox from './Checkbox';
import InputText from './InputText';
import IconX from './icons/IconX';

const InviteModal = (props) => {
	return (
		<div className='modal'>
			<div className='modal-body'>
				<Button
					variant='icon'
					icon={<IconX />}
					classLabel='close-btn'
					action={props.closeInviteModal}
				/>
				<h2>Send invite</h2>
				<InputText
					label='Candidate email'
					defaultValue={'malik.jones@gmail.com'}
				/>

				<h3>Available assessments</h3>
				<p style={{ marginTop: '-0.75rem' }}>
					Select up to 3 assessments to assign to this candidate.
				</p>

				<ul>
					<li>
						<Checkbox
							id='versant'
							label='Versant'
							checked='true'
						/>
					</li>
					<li>
						<Checkbox
							id='watson'
							label='Watson Glasser III'
							checked='true'
						/>
					</li>
					<li>
						<Checkbox
							id='dat'
							label='Differential Abstract Reasoning (DAT)'
							checked='true'
						/>
					</li>
				</ul>

				<Button
					variant='primary'
					text='Send invite'
					action={props.sendInvite}
				/>
			</div>
		</div>
	);
};

export default InviteModal;

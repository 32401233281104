/** @format */

import React from 'react';

const IconDoor = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M12 16.875C13.2925 16.8735 14.5316 16.3594 15.4455 15.4455C16.3594 14.5316 16.8735 13.2925 16.875 12V6C16.875 4.70707 16.3614 3.46709 15.4471 2.55285C14.5329 1.63861 13.2929 1.125 12 1.125C10.7071 1.125 9.46709 1.63861 8.55285 2.55285C7.63861 3.46709 7.125 4.70707 7.125 6V12C7.12649 13.2925 7.64058 14.5316 8.5545 15.4455C9.46842 16.3594 10.7075 16.8735 12 16.875ZM9.375 6C9.375 5.30381 9.65156 4.63613 10.1438 4.14384C10.6361 3.65156 11.3038 3.375 12 3.375C12.6962 3.375 13.3639 3.65156 13.8562 4.14384C14.3484 4.63613 14.625 5.30381 14.625 6V12C14.625 12.6962 14.3484 13.3639 13.8562 13.8562C13.3639 14.3484 12.6962 14.625 12 14.625C11.3038 14.625 10.6361 14.3484 10.1438 13.8562C9.65156 13.3639 9.375 12.6962 9.375 12V6ZM13.125 20.5519V22.5C13.125 22.7984 13.0065 23.0845 12.7955 23.2955C12.5845 23.5065 12.2984 23.625 12 23.625C11.7016 23.625 11.4155 23.5065 11.2045 23.2955C10.9935 23.0845 10.875 22.7984 10.875 22.5V20.5519C8.80129 20.2762 6.89805 19.2574 5.51871 17.6847C4.13937 16.1119 3.37765 14.092 3.375 12C3.375 11.7016 3.49353 11.4155 3.7045 11.2045C3.91548 10.9935 4.20163 10.875 4.5 10.875C4.79837 10.875 5.08452 10.9935 5.2955 11.2045C5.50647 11.4155 5.625 11.7016 5.625 12C5.625 13.6908 6.29665 15.3123 7.49219 16.5078C8.68774 17.7034 10.3092 18.375 12 18.375C13.6908 18.375 15.3123 17.7034 16.5078 16.5078C17.7034 15.3123 18.375 13.6908 18.375 12C18.375 11.7016 18.4935 11.4155 18.7045 11.2045C18.9155 10.9935 19.2016 10.875 19.5 10.875C19.7984 10.875 20.0845 10.9935 20.2955 11.2045C20.5065 11.4155 20.625 11.7016 20.625 12C20.6223 14.092 19.8606 16.1119 18.4813 17.6847C17.1019 19.2574 15.1987 20.2762 13.125 20.5519Z' />
	</svg>
);

export default IconDoor;

/** @format */
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import CandidateDashboard from './pages/CandidateDashboard';
import GenericTemplate from './pages/GenericTemplate';
import LoginContainer from './pages/LoginContainer';
import RecruiterDashboard from './pages/RecruiterDashboard';
import CreateAccountContainer from './pages/CreateAccountContainer';
import RecruiterSeeCandidate from './pages/RecruiterSeeCandidate';
import RecruiterViewResult from './pages/RecruiterViewResult';
import Email from './pages/Email';
import Versant from './pages/Versant';
import WatsonGlaser from './pages/WatsonGlaser';

function App() {
	return (
		<div className='App'>
			<Router>
				<Routes>
					<Route
						path='/'
						element={<LoginContainer />}
					/>
					<Route
						path='/signin'
						element={<LoginContainer />}
					/>
					<Route
						path='/signin/:type'
						element={<LoginContainer />}
					/>
					<Route
						path='/create'
						element={<CreateAccountContainer />}
					/>
					<Route
						path='/malik'
						element={<CandidateDashboard />}
					/>
					<Route
						path='/georgia'
						element={<RecruiterDashboard />}
					/>
					<Route
						path='/georgia/:id'
						element={<RecruiterSeeCandidate />}
					/>
					<Route
						path={`/georgia/:id/:userID`}
						element={<RecruiterViewResult />}
					/>
					<Route
						path='/email'
						element={<Email />}
					/>
					<Route
						path='/malik/versant'
						element={<Versant />}
					/>
					<Route
						path='/malik/watsonGlaser'
						element={<WatsonGlaser />}
					/>
					<Route
						path='/components'
						element={<GenericTemplate />}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;

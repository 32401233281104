/** @format */

import React from 'react';

const IconLocked = ({ size = 24, color = '#211F28' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill={color}
	>
		<path
			d='M20 7.125H17.375V5.25C17.375 3.95707 16.8614 2.71709 15.9471 1.80285C15.0329 0.888615 13.7929 0.375 12.5 0.375C11.2071 0.375 9.96709 0.888615 9.05285 1.80285C8.13861 2.71709 7.625 3.95707 7.625 5.25V7.125H5C4.50272 7.125 4.02581 7.32254 3.67417 7.67417C3.32254 8.02581 3.125 8.50272 3.125 9V19.5C3.125 19.9973 3.32254 20.4742 3.67417 20.8258C4.02581 21.1775 4.50272 21.375 5 21.375H20C20.4973 21.375 20.9742 21.1775 21.3258 20.8258C21.6775 20.4742 21.875 19.9973 21.875 19.5V9C21.875 8.50272 21.6775 8.02581 21.3258 7.67417C20.9742 7.32254 20.4973 7.125 20 7.125ZM9.875 5.25C9.875 4.55381 10.1516 3.88613 10.6438 3.39384C11.1361 2.90156 11.8038 2.625 12.5 2.625C13.1962 2.625 13.8639 2.90156 14.3562 3.39384C14.8484 3.88613 15.125 4.55381 15.125 5.25V7.125H9.875V5.25ZM19.625 19.125H5.375V9.375H19.625V19.125ZM12.5 10.5C11.804 10.5003 11.1297 10.7425 10.5927 11.1853C10.0557 11.628 9.68932 12.2437 9.55636 12.9269C9.4234 13.6101 9.53212 14.3183 9.86391 14.9301C10.1957 15.5419 10.7299 16.0194 11.375 16.2806V16.875C11.375 17.1734 11.4935 17.4595 11.7045 17.6705C11.9155 17.8815 12.2016 18 12.5 18C12.7984 18 13.0845 17.8815 13.2955 17.6705C13.5065 17.4595 13.625 17.1734 13.625 16.875V16.2806C14.2701 16.0194 14.8043 15.5419 15.1361 14.9301C15.4679 14.3183 15.5766 13.6101 15.4436 12.9269C15.3107 12.2437 14.9443 11.628 14.4073 11.1853C13.8703 10.7425 13.196 10.5003 12.5 10.5ZM12.5 12.75C12.6483 12.75 12.7933 12.794 12.9167 12.8764C13.04 12.9588 13.1361 13.0759 13.1929 13.213C13.2497 13.35 13.2645 13.5008 13.2356 13.6463C13.2066 13.7918 13.1352 13.9254 13.0303 14.0303C12.9254 14.1352 12.7918 14.2066 12.6463 14.2356C12.5008 14.2645 12.35 14.2497 12.213 14.1929C12.0759 14.1361 11.9588 14.04 11.8764 13.9167C11.794 13.7933 11.75 13.6483 11.75 13.5C11.75 13.3011 11.829 13.1103 11.9697 12.9697C12.1103 12.829 12.3011 12.75 12.5 12.75Z'
			fill={color}
		/>
	</svg>
);

export default IconLocked;

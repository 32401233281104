/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import SigninForm from '../global/widgets/authentication/SigninForm';
import StickyFooter from '../components/Footer/StickyFooter';
import LogoPearson from '../components/icons/LogoPearson';
import CreateAccountForm from '../global/widgets/authentication/CreateAccountForm';
import { useParams } from 'react-router-dom';

const LoginContainer = () => {
	// const candidates = useSelector((state) => state.users.candidates);
	// const recruiter = useSelector((state) => state.users.recruiter);
	// const recruiterAuthenticated = recruiter.authenticated;
	// const candidateAuthenticated = candidates[0].authenticated;
	const { type } = useParams();
	console.log("Login")
	console.log("Type:", type);
	const [createForm, setCreateForm] = useState(false);

	useEffect(() => {
		if (type === "create") {
			setCreateForm(true);
		}
		else {
			setCreateForm(false);
		}
	}, [type]);
	return (
		<div className='signin-page'>
			<div className='login-container'>
				<div className='branding'>
					<LogoPearson />
					<p>Create an account and let the learning begin!</p>
				</div>


				{createForm ?
					<CreateAccountForm /> : <SigninForm />}
			</div>
			<StickyFooter />
		</div>
	);
};

export default LoginContainer;

import talentLensData from "../data/talentLensData";
import {updateTalentLensStatusReducer} from "../reducer/talentLensReducer"
import { createSlice } from "@reduxjs/toolkit";

const talentLensSlice = createSlice({
    name: 'talentLens',
    initialState: talentLensData,
    reducers: {
        updateTalentLensStatus: updateTalentLensStatusReducer,
    }
});

// export actions
export const {
    updateTalentLensStatus,
} = talentLensSlice.actions;
    
// export  reducer, so it can be added to the store
export default talentLensSlice.reducer;
import Header from '../components/Header';
import StickyFooter from '../components/Footer/StickyFooter';
import Sidebar from '../components/Sidebar';
import Button from '../components/Button';
import BackBtn from '../components/Back';
import Divider from '../components/Divider';
import Score from '../components/Score';
import PerformanceBar from '../components/PerformanceBar';
import userData from '../redux/data/userData';
import { useNavigate, useParams } from 'react-router-dom';

const RecruiterViewResult = () => {

    const { id, userID } = useParams();
    const jobList = userData.jobProfiles.find((job) => job.id === id);
    const candidateDetail = userData.candidates.find((candidate) => candidate.userID === userID);


    return (
        <div className="page-wrapper">
            <Header fullLogo={"true"} />
            <Sidebar />

            <main className="recruiter-view-result">
                <BackBtn text="Back to all candidates" />
                <section className="page-head">
                    <div className='user-profile'>
                        <img
                            src={candidateDetail.portrait}
                            alt={candidateDetail.name}
                        />
                        <h1>{candidateDetail.name}</h1>
                    </div>
                    <Button text="Export results" variant="secondary" />
                </section>
                <div className='job-role'>
                    <h3>Job role : {jobList.name}</h3>
                    <p className='role-fit'>
                        <span className={`${candidateDetail.roleFit}`} />
                        {candidateDetail.roleFit} role fit
                    </p>
                </div>
                <Divider />

                <section className='candidate-assets'>
                    <h2>Relevant badges</h2>
                    <div className='badges'>
                        {candidateDetail.badges.map((badge, i) => (
                            <img
                                key={i}
                                src={badge}
                                alt={`Badge ${i + 1}`}
                            />
                        ))}
                    </div>
                </section>

                <section className='candidate-assets'>
                    <h2>Matching skills</h2>
                    <td className='skill-tags'>
                        <div>
                            {candidateDetail.skills.map((skill, i) => (
                                <span key={i}>{skill}</span>
                            ))}
                        </div>
                    </td>
                </section>

                <section className='candidate-assets'>
                    <h2>Assessment results</h2>
                    <Score score={candidateDetail.assessments.status.versant.total} label="Versant" />
                    <Divider />

                    <Score score={candidateDetail.assessments.status.talentLens.total} label="Watson Glaser III" />
                    <div className="performance-container">
                        {Object.entries(candidateDetail.assessments.status.talentLens.performance).map(([list, value]) => (
                            <PerformanceBar key={list} label={list} value={value} />
                        ))}
                    </div>
                    <Divider />

                    <Score score={candidateDetail.assessments.status.DAR.total} label="DAT" />

                </section>
            </main>
            <StickyFooter />
        </div>
    );
};

export default RecruiterViewResult;

/** @format */

import Header from '../components/Header';
import StickyFooter from '../components/Footer/StickyFooter';
import Sidebar from '../components/Sidebar';
import userData from '../redux/data/userData';
import Button from '../components/Button';
import IconPencil from '../components/icons/IconPencil';
import Select from '../components/Select';
import BackBtn from '../components/Back';
import InviteModal from '../components/InviteModal';
import ProgressBar from '../components/ProgressBar';
import Alert from '../components/Alert';
import IconBell from '../components/icons/IconBell';
import IconRight from '../components/icons/IconRight';

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCandidates } from '../redux/slices/userSlice';

const RecruiterSeeCandidate = () => {
	const [invite, setInvite] = useState(false);
	const [alert, setAlert] = useState(false);

	const navigate = useNavigate();
	const candidates =
		localStorage.getItem('candidates') === null
			? useSelector(getCandidates).payload.users.candidates
			: JSON.parse(localStorage.getItem('candidates'));

	const thisCadidate = candidates.filter((a) => a.userID === 'malik');

	const [assessmentAlert, setAssessmentAlert] = useState(
		thisCadidate[0].interviewSteps.complete > 0
	);
	const { id } = useParams();
	const jobList = userData.jobProfiles.find((job) => job.id === id);

	console.log(candidates);

	const showOptions = [
		{ value: 'Role fit', label: 'Role fit' },
		{ value: 'Matching skills', label: 'Matching skills' },
		{ value: 'Assessments score', label: 'Assessments score' },
	];

	const showInviteModal = (e) => {
		e.preventDefault();
		setInvite(true);
	};

	const sendInvite = () => {
		setInvite(false);
		setAlert(true);

		setTimeout(() => {
			setAlert(false);
		}, 10000);
	};

	const closeInviteModal = () => {
		setInvite(false);
	};

	const dismissAlert = (e) => {
		e.preventDefault();
		setAlert(false);
	};

	const hideAlert = (e) => {
		e.preventDefault();
		setAssessmentAlert(false);
	};
	return (
		<div className='page-wrapper'>
			{alert && (
				<Alert
					type='success'
					message='Invite sent'
					dismissAlert={dismissAlert}
				/>
			)}

			{assessmentAlert ? (
				<Alert
					type='success'
					message='You have new results to review!'
					icon={<IconBell />}
					dismissAlert={hideAlert}
				/>
			) : (
				<></>
			)}
			<Header fullLogo={'true'} />
			<Sidebar />

			<main className='recruiter-dashboard'>
				<BackBtn text='Back to all roles' />
				<section className='title'>
					<h1>{jobList.name}</h1>
					<Button
						text='Edit role'
						variant='secondary'
						icon={<IconPencil color='#0D004D'/>}
						iconPosition='before'
					/>
				</section>

				<Select
					label='Sorted by'
					name='show-select'
					options={showOptions}
				/>

				<table className={`${assessmentAlert ? 'updated' : ''}`}>
					<thead>
						<tr>
							<th>Candidate name</th>
							<th>Assessments</th>
							<th>Relevant badges</th>
							<th>Matching skills</th>
							<th className='role-fit-head'>Role fits</th>
						</tr>
					</thead>
					<tbody>
						{candidates.map((user, index) => (
							<tr
								key={index}
								className={`user-profile ${
									user.userID === 'malik' && assessmentAlert ? 'updated' : ''
								}`}
							>
								<td
									onClick={() =>
										navigate(`/georgia/${jobList.id}/${user.userID}`)
									}
								>
									<img
										src={user.portrait}
										alt={user.name}
									/>
									<h3>{user.name}</h3>
								</td>
								<td className='assessments'>
									{user.interviewSteps.complete === 0 ? (
										<>
											<p>Not started</p>
											<a
												href='javascript:void(0)'
												onClick={(e) => showInviteModal(e)}
												className='send-invite'
											>
												Send invite <IconRight size={14} />
											</a>
										</>
									) : (
										<div>
											{user.interviewSteps.complete ===
											user.interviewSteps.total ? (
												<>
													Completed{' '}
													<h4 className='bold-text'>
														{user.interviewSteps.percent}% average
													</h4>
												</>
											) : (
												<>
													In progress ({user.interviewSteps.complete} /{' '}
													{user.interviewSteps.total} completed)
													<ProgressBar
														totalSteps={user.interviewSteps.total}
														completedSteps={user.interviewSteps.complete}
													/>
												</>
											)}
										</div>
									)}
								</td>
								<td className='badges'>
									<div>
										{user.badges.slice(0, 3).map((badge, i) => (
											<img
												key={i}
												src={badge}
												alt={`Badge ${i + 1}`}
											/>
										))}
										{user.badges.length > 3 && (
											<span>+{user.badges.length - 3} </span>
										)}
									</div>
								</td>
								<td className='skill-tags'>
									<div>
										{user.skills.slice(0, 3).map((skill, i) => (
											<span key={i}>{skill}</span>
										))}
										{user.skills.length > 3 && (
											<span>+{user.skills.length - 3} more</span>
										)}
									</div>
								</td>

								<td className='role-fit'>
									{user.interviewSteps.complete === 0 ? (
										<>--</>
									) : (
										<>
											<span className={`${user.roleFit}`} />
											{user.roleFit}
										</>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</main>

			<StickyFooter />

			{invite ? (
				<InviteModal
					closeInviteModal={closeInviteModal}
					sendInvite={sendInvite}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

export default RecruiterSeeCandidate;

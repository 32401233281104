/** @format */

import React from 'react';
// import { createAsyncThunk } from "@reduxjs/toolkit";

export const getRecruiterReducer = (state, action) => state.recruiter;

// Reducer to get the `requisitions` array
export const getJobProfilesReducer = (state, action) => state.jobProfiles;

// Reducer to get the `candidates` array
export const getCandidatesReducer = (state, action) => state.candidates;

// export const updateCandidateReducer = (state, action) => {
//     const updatedFields = action.payload;

//     // Clone the current state for immutability
//     const nextState = { ...state };
//     const updatedUser = action.payload;

//     // Access the first candidate
//     const keys = Object.keys(updatedUser);

//     // Update only properties that exist in the payload
//     keys.forEach((key) => {
//       if (key && updatedUser[key] !== undefined && updatedUser[key] !== null) {
//         nextState.candidates[0][key] = updatedUser[key];
//       }
//     });

//     // Return the updated state
//     return nextState;
//   }
export const updateRecruiterAuthReducer = (state, action) => {
	const updatedFields = action.payload; // Extract the updated fields from the action payload

	// Create a new state object by cloning the existing state
	const nextState = {
		...state, // Copy all of state properties
		recruiter: {
			...state.recruiter, // Copy all of the recruiter's existing properties
			...updatedFields, // Apply the updated fields from action.payload to the recruiter
		},
	};

	return nextState; // Return the new, updated state
};

export const updateAssessmentStatusReducer = (state, action) => {
	const { userId, assessmentType, subcategory, status } = action.payload;
	console.log(
		'userId:',
		userId,
		'assessmentType:',
		assessmentType,
		'subcategory:',
		subcategory,
		'status:',
		status
	);
	// Find the candidate by userId
	const candidate = state.candidates.find(
		(candidate) => candidate.userID === userId
	);
	//console.log("Candidate:", candidate)
	// Check if the candidate and assessment type exist
	if (candidate && candidate.assessments.status[assessmentType]) {
		// Update the specified subcategory with the new status

		candidate.assessments.status[assessmentType][subcategory] = status;
	}
};

export const updateInterviewStepsReducer = (state, action) => {
	const { userId } = action.payload;
	// Find the candidate by userId
	const candidate = state.candidates.find(
		(candidate) => candidate.userID === userId
	);
	if (candidate) {
		candidate.interviewSteps.complete = candidate.interviewSteps.complete + 1;
		candidate.interviewSteps.notstarted =
			candidate.interviewSteps.notstarted - 1;
	}
};

export const updateCandidateReducer = (state, action) => {
	const updatedFields = action.payload;

	// Create a new state object by cloning the existing state
	const nextState = {
		...state, // Copy all of state properties
		candidates: [
			{
				...state.candidates[0], // Copy all of the first candidate's properties
				...updatedFields, // Apply the updated fields from action.payload to the first candidate
			},
			...state.candidates.slice(1), // Keep the rest of the candidates unchanged
		],
	};

	return nextState; // Return the new, updated state
};

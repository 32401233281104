/** @format */

const LogoWatsonGlaser = ({ width = 290, height = 32, color = '#512EAB' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 290 32'
			preserveAspectRatio='xMidYMid meet' 
			fill='none'
		>
			<path
				d='M285.995 30.1201V1.16577H289.803V30.1201H285.995Z'
				fill={color}
			/>
			<path
				d='M277.69 30.1201V1.16577H281.499V30.1201H277.69Z'
				fill={color}
			/>
			<path
				d='M269.386 30.1201V1.16577H273.194V30.1201H269.386Z'
				fill={color}
			/>
			<path
				d='M252.54 30.1201V9.17191H256.076V13.0195L255.688 12.4754C256.18 11.2836 256.931 10.4026 257.942 9.83261C258.952 9.23668 260.183 8.93872 261.634 8.93872H262.916V12.3588H261.09C259.613 12.3588 258.421 12.8252 257.514 13.758C256.607 14.6648 256.154 15.9603 256.154 17.6444V30.1201H252.54Z'
				fill={color}
			/>
			<path
				d='M240.248 30.5865C238.227 30.5865 236.427 30.1071 234.846 29.1485C233.266 28.1898 232.022 26.8814 231.115 25.2231C230.208 23.539 229.755 21.6605 229.755 19.5877C229.755 17.489 230.195 15.6235 231.076 13.9912C231.983 12.3589 233.201 11.0763 234.73 10.1436C236.284 9.1849 238.02 8.70557 239.937 8.70557C241.492 8.70557 242.865 8.99057 244.057 9.56059C245.275 10.1047 246.298 10.8561 247.127 11.8148C247.982 12.7475 248.63 13.8228 249.071 15.0405C249.537 16.2324 249.77 17.4761 249.77 18.7716C249.77 19.0566 249.744 19.3804 249.693 19.7432C249.667 20.08 249.628 20.4039 249.576 20.7148H232.398V17.6056H247.477L245.767 19.0047C246 17.6574 245.871 16.4526 245.379 15.3903C244.886 14.328 244.161 13.4859 243.202 12.8641C242.243 12.2423 241.155 11.9313 239.937 11.9313C238.72 11.9313 237.606 12.2423 236.595 12.8641C235.585 13.4859 234.794 14.3798 234.224 15.5458C233.68 16.6858 233.46 18.0461 233.564 19.6266C233.46 21.1553 233.693 22.5026 234.263 23.6685C234.859 24.8086 235.688 25.7025 236.751 26.3502C237.839 26.972 239.018 27.283 240.287 27.283C241.686 27.283 242.865 26.9591 243.824 26.3113C244.783 25.6636 245.56 24.8345 246.156 23.824L249.187 25.3786C248.773 26.3372 248.125 27.2182 247.244 28.0214C246.389 28.7987 245.366 29.4205 244.174 29.8869C243.008 30.3533 241.699 30.5865 240.248 30.5865Z'
				fill={color}
			/>
			<path
				d='M220.204 30.5865C218.106 30.5865 216.279 30.0683 214.724 29.0319C213.17 27.9955 212.069 26.5963 211.421 24.8345L214.297 23.4353C214.893 24.679 215.709 25.6636 216.745 26.3891C217.782 27.1145 218.935 27.4773 220.204 27.4773C221.344 27.4773 222.29 27.2052 223.041 26.6611C223.793 26.117 224.169 25.4045 224.169 24.5236C224.169 23.9017 223.987 23.4094 223.624 23.0467C223.288 22.658 222.873 22.3601 222.381 22.1528C221.888 21.9196 221.435 21.7512 221.021 21.6476L217.872 20.7537C215.981 20.2096 214.595 19.4323 213.714 18.4218C212.859 17.4113 212.431 16.2324 212.431 14.8851C212.431 13.6414 212.742 12.5661 213.364 11.6593C214.012 10.7265 214.88 10.0011 215.968 9.48286C217.082 8.96466 218.326 8.70557 219.699 8.70557C221.539 8.70557 223.184 9.17194 224.635 10.1047C226.112 11.0375 227.161 12.3459 227.783 14.03L224.829 15.3903C224.363 14.3021 223.663 13.4471 222.731 12.8252C221.798 12.1775 220.748 11.8536 219.583 11.8536C218.52 11.8536 217.678 12.1257 217.056 12.6698C216.434 13.188 216.124 13.8487 216.124 14.6519C216.124 15.2478 216.279 15.7401 216.59 16.1287C216.901 16.4915 217.277 16.7765 217.717 16.9838C218.157 17.1651 218.585 17.3206 219 17.4502L222.42 18.4606C224.13 18.9529 225.451 19.7173 226.384 20.7537C227.343 21.7901 227.822 23.0337 227.822 24.4847C227.822 25.6506 227.498 26.7 226.85 27.6327C226.202 28.5655 225.309 29.291 224.169 29.8092C223.029 30.3274 221.707 30.5865 220.204 30.5865Z'
				fill={color}
			/>
			<path
				d='M198.432 30.5865C197.059 30.5865 195.841 30.3403 194.779 29.848C193.743 29.3298 192.926 28.6303 192.33 27.7493C191.734 26.8425 191.437 25.8061 191.437 24.6401C191.437 23.526 191.67 22.5285 192.136 21.6476C192.628 20.7407 193.38 19.9764 194.39 19.3545C195.427 18.7327 196.722 18.2922 198.277 18.0331L206.05 16.7506V19.782L199.093 20.948C197.746 21.1812 196.761 21.6087 196.139 22.2305C195.543 22.8524 195.245 23.6167 195.245 24.5236C195.245 25.3786 195.582 26.0911 196.256 26.6611C196.955 27.2311 197.823 27.5161 198.86 27.5161C200.181 27.5161 201.321 27.2441 202.28 26.7C203.264 26.13 204.029 25.3656 204.573 24.407C205.143 23.4483 205.428 22.386 205.428 21.22V15.8956C205.428 14.7555 205 13.8357 204.145 13.1362C203.316 12.4107 202.215 12.0479 200.842 12.0479C199.65 12.0479 198.588 12.3589 197.655 12.9807C196.748 13.5766 196.074 14.3798 195.634 15.3903L192.486 13.758C192.875 12.7993 193.496 11.9443 194.351 11.1929C195.206 10.4156 196.204 9.80673 197.344 9.36627C198.484 8.9258 199.676 8.70557 200.92 8.70557C202.526 8.70557 203.938 9.01648 205.156 9.63832C206.374 10.2342 207.319 11.0763 207.993 12.1645C208.693 13.2268 209.042 14.4705 209.042 15.8956V30.1201H205.506V26.1559L206.166 26.3891C205.726 27.2182 205.13 27.9437 204.379 28.5655C203.627 29.1873 202.746 29.6796 201.736 30.0424C200.725 30.4051 199.624 30.5865 198.432 30.5865Z'
				fill={color}
			/>
			<path
				d='M185.012 30.1201V0.699463H188.627V30.1201H185.012Z'
				fill={color}
			/>
			<path
				d='M168.985 30.5865C166.938 30.5865 165.046 30.2238 163.311 29.4983C161.575 28.7469 160.072 27.6976 158.802 26.3503C157.533 25.0029 156.535 23.4224 155.81 21.6087C155.11 19.7951 154.76 17.8129 154.76 15.6624C154.76 13.486 155.11 11.4909 155.81 9.67724C156.509 7.86355 157.494 6.28304 158.763 4.93573C160.033 3.58842 161.536 2.55202 163.272 1.82654C165.008 1.07516 166.899 0.699463 168.946 0.699463C170.941 0.699463 172.729 1.04925 174.309 1.74881C175.916 2.44838 177.263 3.35523 178.351 4.46935C179.465 5.58348 180.256 6.77533 180.722 8.04492L177.302 9.7161C176.628 8.03196 175.579 6.6976 174.154 5.71303C172.729 4.72845 170.993 4.23616 168.946 4.23616C166.925 4.23616 165.124 4.7155 163.544 5.67416C161.989 6.63283 160.771 7.96719 159.89 9.67724C159.009 11.3873 158.569 13.3824 158.569 15.6624C158.569 17.9166 159.009 19.8987 159.89 21.6087C160.797 23.3188 162.028 24.6532 163.583 25.6118C165.163 26.5705 166.964 27.0498 168.985 27.0498C170.747 27.0498 172.34 26.6741 173.765 25.9227C175.19 25.1714 176.317 24.135 177.146 22.8136C177.976 21.4921 178.39 19.9635 178.39 18.2275V16.4397L180.139 18.072H168.946V14.7685H182.238V17.3336C182.238 19.3546 181.888 21.1812 181.188 22.8136C180.489 24.4459 179.53 25.845 178.312 27.011C177.095 28.151 175.682 29.0319 174.076 29.6538C172.47 30.2756 170.773 30.5865 168.985 30.5865Z'
				fill={color}
			/>
			<path
				d='M129.812 30.1201V9.17194H133.349V13.2528L132.766 12.903C133.284 11.5816 134.113 10.5581 135.253 9.83265C136.419 9.08126 137.779 8.70557 139.334 8.70557C140.837 8.70557 142.171 9.04239 143.337 9.71605C144.529 10.3897 145.462 11.3225 146.135 12.5143C146.835 13.7062 147.185 15.0535 147.185 16.5563V30.1201H143.531V17.7222C143.531 16.5563 143.324 15.5717 142.909 14.7685C142.495 13.9653 141.899 13.3434 141.122 12.903C140.37 12.4366 139.502 12.2034 138.518 12.2034C137.533 12.2034 136.652 12.4366 135.875 12.903C135.124 13.3434 134.528 13.9782 134.087 14.8073C133.647 15.6106 133.426 16.5822 133.426 17.7222V30.1201H129.812Z'
				fill={color}
			/>
			<path
				d='M116.275 30.5865C114.254 30.5865 112.428 30.1201 110.795 29.1873C109.163 28.2287 107.867 26.9202 106.909 25.262C105.95 23.6038 105.471 21.7253 105.471 19.6266C105.471 17.5279 105.937 15.6624 106.87 14.03C107.829 12.3977 109.124 11.1022 110.756 10.1436C112.389 9.1849 114.228 8.70557 116.275 8.70557C118.296 8.70557 120.123 9.1849 121.755 10.1436C123.387 11.0763 124.67 12.3589 125.603 13.9912C126.561 15.6235 127.041 17.502 127.041 19.6266C127.041 21.7512 126.548 23.6426 125.564 25.3008C124.579 26.9332 123.271 28.2287 121.638 29.1873C120.032 30.1201 118.244 30.5865 116.275 30.5865ZM116.275 27.0886C117.571 27.0886 118.737 26.7648 119.773 26.117C120.835 25.4693 121.664 24.5754 122.26 23.4353C122.882 22.2953 123.193 21.0257 123.193 19.6266C123.193 18.2015 122.882 16.9449 122.26 15.8567C121.664 14.7426 120.835 13.8616 119.773 13.2139C118.737 12.5402 117.571 12.2034 116.275 12.2034C114.954 12.2034 113.762 12.5402 112.7 13.2139C111.663 13.8616 110.834 14.7426 110.212 15.8567C109.59 16.9449 109.279 18.2015 109.279 19.6266C109.279 21.0257 109.59 22.2953 110.212 23.4353C110.834 24.5754 111.663 25.4693 112.7 26.117C113.762 26.7648 114.954 27.0886 116.275 27.0886Z'
				fill={color}
			/>
			<path
				d='M95.9202 30.5865C93.8215 30.5865 91.9948 30.0683 90.4402 29.0319C88.8856 27.9955 87.7845 26.5963 87.1367 24.8345L90.0127 23.4353C90.6086 24.679 91.4248 25.6636 92.4612 26.3891C93.4976 27.1145 94.6506 27.4773 95.9202 27.4773C97.0602 27.4773 98.0059 27.2052 98.7573 26.6611C99.5087 26.117 99.8844 25.4045 99.8844 24.5236C99.8844 23.9017 99.703 23.4094 99.3403 23.0467C99.0035 22.658 98.5889 22.3601 98.0966 22.1528C97.6043 21.9196 97.1509 21.7512 96.7363 21.6476L93.5883 20.7537C91.6969 20.2096 90.3107 19.4323 89.4297 18.4218C88.5747 17.4113 88.1472 16.2324 88.1472 14.8851C88.1472 13.6414 88.4581 12.5661 89.08 11.6593C89.7277 10.7265 90.5957 10.0011 91.6839 9.48286C92.798 8.96466 94.0417 8.70557 95.4149 8.70557C97.2545 8.70557 98.8998 9.17194 100.351 10.1047C101.828 11.0375 102.877 12.3459 103.499 14.03L100.545 15.3903C100.079 14.3021 99.3791 13.4471 98.4464 12.8252C97.5136 12.1775 96.4643 11.8536 95.2983 11.8536C94.236 11.8536 93.394 12.1257 92.7721 12.6698C92.1503 13.188 91.8394 13.8487 91.8394 14.6519C91.8394 15.2478 91.9948 15.7401 92.3057 16.1287C92.6167 16.4915 92.9924 16.7765 93.4328 16.9838C93.8733 17.1651 94.3008 17.3206 94.7154 17.4502L98.1355 18.4606C99.8455 18.9529 101.167 19.7173 102.1 20.7537C103.058 21.7901 103.538 23.0337 103.538 24.4847C103.538 25.6506 103.214 26.7 102.566 27.6327C101.918 28.5655 101.024 29.291 99.8844 29.8092C98.7444 30.3274 97.4229 30.5865 95.9202 30.5865Z'
				fill={color}
			/>
			<path
				d='M83.4648 30.3534C81.4179 30.3534 79.8374 29.7704 78.7233 28.6044C77.6351 27.4385 77.091 25.7932 77.091 23.6686V12.631H73.2822V9.17202H74.0595C74.9923 9.17202 75.7307 8.88701 76.2748 8.31699C76.8189 7.74698 77.091 6.99559 77.091 6.06284V4.35278H80.7054V9.17202H85.4081V12.631H80.7054V23.552C80.7054 24.2516 80.8091 24.8605 81.0163 25.3787C81.2495 25.8969 81.6252 26.3114 82.1434 26.6223C82.6616 26.9073 83.3482 27.0498 84.2032 27.0498C84.3846 27.0498 84.6048 27.0369 84.8639 27.011C85.149 26.9851 85.4081 26.9592 85.6412 26.9333V30.1202C85.3044 30.1979 84.9287 30.2497 84.5142 30.2756C84.0996 30.3274 83.7498 30.3534 83.4648 30.3534Z'
				fill={color}
			/>
			<path
				d='M61.0616 30.5865C59.6884 30.5865 58.4706 30.3403 57.4083 29.848C56.3719 29.3298 55.5557 28.6303 54.9598 27.7493C54.3639 26.8425 54.0659 25.8061 54.0659 24.6401C54.0659 23.526 54.2991 22.5285 54.7655 21.6476C55.2578 20.7407 56.0092 19.9764 57.0196 19.3545C58.056 18.7327 59.3515 18.2922 60.9061 18.0331L68.6791 16.7506V19.782L61.7223 20.948C60.375 21.1812 59.3904 21.6087 58.7686 22.2305C58.1726 22.8524 57.8747 23.6167 57.8747 24.5236C57.8747 25.3786 58.2115 26.0911 58.8852 26.6611C59.5847 27.2311 60.4527 27.5161 61.4891 27.5161C62.8105 27.5161 63.9505 27.2441 64.9092 26.7C65.8938 26.13 66.6581 25.3656 67.2022 24.407C67.7723 23.4483 68.0573 22.386 68.0573 21.22V15.8956C68.0573 14.7555 67.6297 13.8357 66.7747 13.1362C65.9456 12.4107 64.8444 12.0479 63.4712 12.0479C62.2794 12.0479 61.217 12.3589 60.2843 12.9807C59.3774 13.5766 58.7038 14.3798 58.2633 15.3903L55.1153 13.758C55.5039 12.7993 56.1258 11.9443 56.9808 11.1929C57.8358 10.4156 58.8333 9.80673 59.9734 9.36627C61.1134 8.9258 62.3053 8.70557 63.5489 8.70557C65.1554 8.70557 66.5674 9.01648 67.7852 9.63832C69.003 10.2342 69.9487 11.0763 70.6223 12.1645C71.3219 13.2268 71.6717 14.4705 71.6717 15.8956V30.1201H68.135V26.1559L68.7957 26.3891C68.3552 27.2182 67.7593 27.9437 67.0079 28.5655C66.2565 29.1873 65.3756 29.6796 64.3651 30.0424C63.3546 30.4051 62.2534 30.5865 61.0616 30.5865Z'
				fill={color}
			/>
			<path
				d='M25.4592 30.1201L17.3364 1.16577H21.3395L28.1408 26.6611H27.2081L34.2815 1.16577H38.2846L45.3191 26.6611H44.3475L51.1877 1.16577H55.1908L47.068 30.1201H42.8318L35.7584 4.74134H36.7688L29.6954 30.1201H25.4592Z'
				fill={color}
			/>
		</svg>
	);
};

export default LogoWatsonGlaser;

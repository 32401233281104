/** @format */

import React from 'react';

const IconRight = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M17.2959 12.796L9.7959 20.296C9.58455 20.5073 9.29791 20.626 8.99902 20.626C8.70014 20.626 8.41349 20.5073 8.20215 20.296C7.9908 20.0846 7.87207 19.798 7.87207 19.4991C7.87207 19.2002 7.9908 18.9136 8.20215 18.7022L14.9062 12L8.20402 5.29596C8.09937 5.19131 8.01636 5.06708 7.95973 4.93035C7.90309 4.79362 7.87394 4.64708 7.87394 4.49908C7.87394 4.35109 7.90309 4.20455 7.95973 4.06782C8.01636 3.93109 8.09937 3.80686 8.20402 3.70221C8.30867 3.59756 8.4329 3.51455 8.56963 3.45792C8.70636 3.40128 8.8529 3.37213 9.0009 3.37213C9.14889 3.37213 9.29543 3.40128 9.43216 3.45792C9.56889 3.51455 9.69312 3.59756 9.79777 3.70221L17.2978 11.2022C17.4025 11.3068 17.4856 11.4311 17.5422 11.568C17.5988 11.7048 17.6279 11.8514 17.6277 11.9995C17.6275 12.1475 17.5981 12.2941 17.5412 12.4308C17.4843 12.5675 17.4009 12.6916 17.2959 12.796Z' />
	</svg>
);

export default IconRight;

/** @format */
import imgRepeat from '../../assets/images/img_repeat.png';
import imgListen from '../../assets/images/img_listen.png';

const RepeatItems = (props) => {
	return (
		<section className='assessment-question'>
			<h1>2. Repeat</h1>

			<p>{props.data.instructions}</p>

			<div className='item-details'>
				<span>Question: 1 of 16</span> | <span>Time Left: 0:25</span>
			</div>

			<div className='listen-repeat'>
				<div>
					<p>Please listen</p>
					<img
						src={imgListen}
						alt=''
					/>
				</div>
				<div>
					<p>Repeat what you heard</p>
					<img
						src={imgRepeat}
						alt=''
					/>
				</div>
			</div>
		</section>
	);
};
export default RepeatItems;

/** @format */

import React from 'react';

const IconSquares = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M9.375 3.375H5.25C4.75272 3.375 4.27581 3.57254 3.92417 3.92417C3.57254 4.27581 3.375 4.75272 3.375 5.25V9.375C3.375 9.87228 3.57254 10.3492 3.92417 10.7008C4.27581 11.0525 4.75272 11.25 5.25 11.25H9.375C9.87228 11.25 10.3492 11.0525 10.7008 10.7008C11.0525 10.3492 11.25 9.87228 11.25 9.375V5.25C11.25 4.75272 11.0525 4.27581 10.7008 3.92417C10.3492 3.57254 9.87228 3.375 9.375 3.375ZM9 9H5.625V5.625H9V9ZM18.75 3.375H14.625C14.1277 3.375 13.6508 3.57254 13.2992 3.92417C12.9475 4.27581 12.75 4.75272 12.75 5.25V9.375C12.75 9.87228 12.9475 10.3492 13.2992 10.7008C13.6508 11.0525 14.1277 11.25 14.625 11.25H18.75C19.2473 11.25 19.7242 11.0525 20.0758 10.7008C20.4275 10.3492 20.625 9.87228 20.625 9.375V5.25C20.625 4.75272 20.4275 4.27581 20.0758 3.92417C19.7242 3.57254 19.2473 3.375 18.75 3.375ZM18.375 9H15V5.625H18.375V9ZM9.375 12.75H5.25C4.75272 12.75 4.27581 12.9475 3.92417 13.2992C3.57254 13.6508 3.375 14.1277 3.375 14.625V18.75C3.375 19.2473 3.57254 19.7242 3.92417 20.0758C4.27581 20.4275 4.75272 20.625 5.25 20.625H9.375C9.87228 20.625 10.3492 20.4275 10.7008 20.0758C11.0525 19.7242 11.25 19.2473 11.25 18.75V14.625C11.25 14.1277 11.0525 13.6508 10.7008 13.2992C10.3492 12.9475 9.87228 12.75 9.375 12.75ZM9 18.375H5.625V15H9V18.375ZM18.75 12.75H14.625C14.1277 12.75 13.6508 12.9475 13.2992 13.2992C12.9475 13.6508 12.75 14.1277 12.75 14.625V18.75C12.75 19.2473 12.9475 19.7242 13.2992 20.0758C13.6508 20.4275 14.1277 20.625 14.625 20.625H18.75C19.2473 20.625 19.7242 20.4275 20.0758 20.0758C20.4275 19.7242 20.625 19.2473 20.625 18.75V14.625C20.625 14.1277 20.4275 13.6508 20.0758 13.2992C19.7242 12.9475 19.2473 12.75 18.75 12.75ZM18.375 18.375H15V15H18.375V18.375Z' />
	</svg>
);

export default IconSquares;

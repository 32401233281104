/** @format */

const Button = (props) => {
	return (
		<button
			className={`btn ${props.variant} ${props.size} ${props.classLabel} ${props.iconPosition}`}
			onClick={props.action}
			disabled={props.disabled}
		>
			{props.icon}
			{props.text}
		</button>
	);
};
export default Button;

import React from 'react';
import CreateAccount from '../global/widgets/authentication/CreateAccountForm';
import StickyFooter from '../components/Footer/StickyFooter';
import LogoPearson from '../components/icons/LogoPearson';

const CreateAccountContainer = () => {
    
    return (
        <div className='signin-page'>
            <div className='login-container'>
                <div className='branding'>
                    <LogoPearson />
                    <p>Create an account and let the learning begin!</p>
                </div>
                <CreateAccount />
            </div>
            <StickyFooter />
        </div>
    );
};

export default CreateAccountContainer;
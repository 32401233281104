/** @format */

import React from 'react';

const IconArrowLeft = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill='none'
	>
		<path
			d='M21.375 12C21.375 12.2983 21.2565 12.5845 21.0455 12.7955C20.8345 13.0064 20.5484 13.125 20.25 13.125H6.46875L11.2988 17.954C11.5101 18.1654 11.6288 18.452 11.6288 18.7509C11.6288 19.0498 11.5101 19.3364 11.2988 19.5478C11.0874 19.7591 10.8008 19.8779 10.5019 19.8779C10.203 19.8779 9.91635 19.7591 9.705 19.5478L2.955 12.7978C2.85012 12.6933 2.76691 12.5691 2.71012 12.4323C2.65334 12.2956 2.62411 12.149 2.62411 12.0009C2.62411 11.8528 2.65334 11.7062 2.71012 11.5695C2.76691 11.4327 2.85012 11.3086 2.955 11.204L9.705 4.45404C9.80965 4.34939 9.93388 4.26638 10.0706 4.20975C10.2073 4.15311 10.3539 4.12396 10.5019 4.12396C10.6499 4.12396 10.7964 4.15311 10.9331 4.20975C11.0699 4.26638 11.1941 4.34939 11.2988 4.45404C11.4034 4.55869 11.4864 4.68292 11.543 4.81965C11.5997 4.95638 11.6288 5.10292 11.6288 5.25091C11.6288 5.39891 11.5997 5.54545 11.543 5.68218C11.4864 5.81891 11.4034 5.94314 11.2988 6.04779L6.46875 10.875H20.25C20.5484 10.875 20.8345 10.9935 21.0455 11.2045C21.2565 11.4155 21.375 11.7016 21.375 12Z'
			fill={color}
		/>
	</svg>
);

export default IconArrowLeft;

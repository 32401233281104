import React from 'react';
import IconDown from '../assets/images/icon-down-arrow.svg';

const PerformanceBar = ({ label, value }) => {

    const progressWidth = (value / 5) * 100;

    return (
        <div className="performance-bar">
            <label>{`${label.charAt(0).toUpperCase() + label.slice(1)}`}</label>

            <div className="bar-container">

                <div className="unfilled-bar">
                    <span className="scale-left">0</span>
                    <span className="scale-right">5</span>

                    <div className="filled-bar"
                        style={{ left: `calc(${(progressWidth)}% - 2.75rem)` }} />
                    <div
                        className="marker-bar"
                        style={{ left: `calc(${(progressWidth)}% - 1px)` }}
                    />
                </div>

                <img
                    src={IconDown}
                    alt="Icon"
                    className="icon"
                    style={{ left: `${progressWidth}%` }}
                />
            </div>

            <p>{value}</p>
        </div>
    );
}

export default PerformanceBar;

/** @format */

import IconSquares from './icons/IconSquares';
import IconTemplates from '../components/icons/IconTemplates';

const Sidebar = (props) => {
	return (
		<aside className='sidebar'>
			<ul>
				<li className='selected'>
					<button>
						<IconSquares /> Dashboard
					</button>
				</li>
				<li>
					<button>
						<IconTemplates color='#656270' /> My templates
					</button>
				</li>
			</ul>
		</aside>
	);
};

export default Sidebar;

import { serializeImage } from "../../utils/serializeImage";

import skill1 from "../../assets/images/badge/cardiff-school.png";
import skill2 from "../../assets/images/badge/coding-green.png";
import skill3 from "../../assets/images/badge/concordia-univ.png";
import skill4 from "../../assets/images/badge/minnesota-state.png";
import skill5 from "../../assets/images/badge/nwca.png";
import skill6 from "../../assets/images/badge/programming.png";
import skill7 from "../../assets/images/badge/rumos-practitioner.png";
import skill8 from "../../assets/images/badge/sw-maintenance.png";

import person1 from "../../assets/images/avatar/malik.png";
import person2 from "../../assets/images/avatar/jessica.png";
import person3 from "../../assets/images/avatar/thomas.png";
import person4 from "../../assets/images/avatar/ben.png";
import person5 from "../../assets/images/avatar/sasha.png";
import person6 from "../../assets/images/avatar/tyler.png";

export const badge1 = serializeImage(skill1);
export const badge2 = serializeImage(skill2);
export const badge3 = serializeImage(skill3);
export const badge4 = serializeImage(skill4);
export const badge5 = serializeImage(skill5);
export const badge6 = serializeImage(skill6);
export const badge7 = serializeImage(skill7);
export const badge8 = serializeImage(skill8);

export const avatar1 = serializeImage(person1);
export const avatar2 = serializeImage(person2);
export const avatar3 = serializeImage(person3);
export const avatar4 = serializeImage(person4);
export const avatar5 = serializeImage(person5);
export const avatar6 = serializeImage(person6);

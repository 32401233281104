import React, {useEffect, useRef} from "react";
// import displaySvgIcon from "../../utils/displaySvgIcon";

const Text = ({
  type,
  register,
  setValue,
  className,
  id,
  testId,
  labelClass,
  name,
  required,
  placeholder,
  placeholderClass,
  clearInput,
}) => {


useEffect(()=>{
  //console.log("clearInput", clearInput);
  if(clearInput ){
    setValue(name, "")
  }
}, [clearInput]);

  return (

    <div className={className}>
      <input
        type={type ? type : "text"}
        aria-labelledby={id}
        {...register(name)}
        className={placeholderClass ? `${placeholderClass}` : ``}
        id={id}
        data-testid={testId}
        placeholder={placeholder ? placeholder : undefined}
        name={name}
        required={required ? required : false}
      />
    </div>
  );
};

export default Text;


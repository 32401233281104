/** @format */

const ReconstuctionItems = (props) => {
	return (
		<section className='assessment-question'>
			<h1>4. Reconstruction</h1>

			<p>{props.data.instructions}</p>

			<div className='item-details'>
				<span>Question: 1 of 16</span> | <span>Time Left: 0:25</span>
			</div>

			<h2>Rewrite the passage using your own words</h2>

			<textarea className='inline-text'></textarea>
		</section>
	);
};
export default ReconstuctionItems;

/** @format */

import formatDate from '../../utils/formatDate';
import * as images from './images';

const userData = {
	recruiter: {
		firstName: 'Georgia',
		lastName: '',
		email: 'georgia.doe@pearson.com',
		password: 'pa$$word123!',
		authenticated: false,
	},
	jobProfiles: [
		{
			name: 'Technician Level II',
			id: 'TEST001234',
			date: formatDate(new Date('2024-03-07')),
			percent: 52,
			applicants: {
				total: 21,
				recommended: {
					total: 8,
					complete: 7,
					get incomplete() {
						return this.total - this.complete; // Use `this` to reference the current object
					},
				},
				totalComplete: 3,
				inProgress: {
					value: 2,
					lastActivity: 'Yesterday',
				},
				sent: 6,
				get notStarted() {
					return this.total - this.totalComplete - this.inProgress.value; // Use `this` for properties in the same object
				},
			},
		},
		{
			name: 'Technician Level II',
			id: 'TEST001248',
			date: formatDate(new Date('2024-03-12')),
			percent: 30,
			applicants: {
				total: 10,
				recommended: {
					total: 3,
					complete: 1,
					get incomplete() {
						return this.total - this.complete; // Use `this` to reference the current object
					},
				},
				totalComplete: 3,
				inProgress: {
					value: 5,
					lastActivity: '2 days ago',
				},
				sent: 1,
				get notStarted() {
					return this.total - this.totalComplete - this.inProgress.value; // Use `this` for properties in the same object
				},
			},
		},
		{
			name: 'Sales Manager',
			id: 'TEST001241',
			date: formatDate(new Date('2024-03-12')),
			percent: 86,
			applicants: {
				total: 9,
				recommended: {
					total: 6,
					complete: 3,
					get incomplete() {
						return this.total - this.complete; // Use `this` to reference the current object
					},
				},
				totalComplete: 6,
				inProgress: {
					value: 2,
					lastActivity: '1 week ago',
				},
				sent: 1,
				get notStarted() {
					return this.total - this.totalComplete - this.inProgress.value; // Use `this` for properties in the same object
				},
			},
		},
	],
	candidates: [
		{
			name: 'Malik Jones',
			userID: 'malik',
			email: 'malik.jones@gmail.com',
			password: 'pa$$word123!',
			authenticated: false,
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 0,
				notstarted: 3,
				percent: 52,
			},
			lastStarted: '',
			lastActivity: '',
			portrait: images.avatar1,
			badges: [images.badge1, images.badge2, images.badge3, images.badge5],
			skills: [
				'Critical Thinking',
				'Debugging',
				'Database',
				'Code Review',
				'Technology Strategies',
				'C#',
				'Java',
				'Python',
				'C++',
			],
			roleFit: 'medium',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 78,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 3.21,
							cooperation: 4.12,
							adaptability: 2.18,
							achievement: 3.54,
							tolerance: 3.98,
							persistence: 3.51,
							independence: 2.85,
						},
						total: 85,
					},
					DAR: {
						total: 88,
					},
				},
			},
		},
		{
			name: 'Jane Doe',
			userID: 'jane',
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 3,
				notstarted: 0,
				percent: 86,
			},
			lastStarted: '5 days ago',
			lastActivity: 'yesterday',
			portrait: images.avatar2,
			badges: [
				images.badge2,
				images.badge3,
				images.badge4,
				images.badge5,
				images.badge6,
				images.badge1,
			],
			skills: [
				'Software Coding',
				'Codebase',
				'Testing',
				'Debugging',
				'HTML Editors',
				'Code Review',
				'React',
				'Swift',
				'Python',
				'GitHub',
				'Technology Strategies',
				'Code Maintenance',
				'Programming Language Implementation',
				'React',
				'JavaScript',
			],
			roleFit: 'high',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 91,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 4.25,
							cooperation: 3.97,
							adaptability: 4.39,
							achievement: 3.21,
							tolerance: 4.08,
							persistence: 3.8,
							independence: 2.15,
						},
						total: 85,
					},
					DAR: {
						total: 82,
					},
				},
			},
		},
		{
			name: 'James Smith',
			userID: 'james',
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 1,
				notstarted: 1,
				percent: '',
			},
			lastStarted: '5 days ago',
			lastActivity: 'yesterday',
			portrait: images.avatar4,
			badges: [images.badge5, images.badge6],
			skills: [
				'Software Coding',
				'Codebase',
				'MaintenanceTesting',
				'Critical Thinking',
				'Database',
				'Technology Strategies',
				'AI',
				'troubleshooting',
				'Programming Language Implementation',
				'Innovative Thinking',
			],
			roleFit: 'low',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 78,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 3.45,
							cooperation: 4.62,
							adaptability: 2.97,
							achievement: 3.45,
							tolerance: 2.08,
							persistence: 4.1,
							independence: 2.5,
						},
						total: 82,
					},
					DAR: {
						total: 76,
					},
				},
			},
		},
		{
			name: 'Sasha Baker',
			userID: 'sasha',
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 1,
				notstarted: 2,
			},
			lastStarted: '1 days ago',
			lastActivity: 'yesterday',
			portrait: images.avatar5,
			badges: [
				images.badge6,
				images.badge7,
				images.badge8,
				images.badge2,
				images.badge4,
			],
			skills: [
				'Database',
				'Programming',
				'Parsing',
				'Debugging',
				'Data analysis',
				'Collaboration',
				'C++',
				'Version control',
			],
			roleFit: 'pending',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 80,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 3.45,
							cooperation: 2.12,
							adaptability: 2.07,
							achievement: 4.45,
							tolerance: 3.88,
							persistence: 4.1,
							independence: 2.15,
						},
						total: 85,
					},
					DAR: {
						total: 76,
					},
				},
			},
		},
		{
			name: 'Ben Irving',
			userID: 'ben',
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 2,
				notstarted: 1,
				percent: 18,
			},
			lastStarted: '17 days ago',
			lastActivity: '1 week ago',
			portrait: images.avatar3,
			badges: [images.badge4, images.badge2],
			skills: [
				'Testing',
				'Problem solving',
				'Database',
				'SQL',
				'Java',
				'Debugging',
				'PHP',
			],
			roleFit: 'low',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 75,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 2.55,
							cooperation: 4.54,
							adaptability: 1.97,
							achievement: 3.75,
							tolerance: 4.48,
							persistence: 4.1,
							independence: 2.45,
						},
						total: 67,
					},
					DAR: {
						total: 60,
					},
				},
			},
		},
		{
			name: 'Tyler Dowry',
			userID: 'tyler',
			jobType: 'Tech II',
			language: 'US English',
			interviewSteps: {
				total: 3,
				complete: 3,
				notstarted: 0,
				percent: 79,
			},
			lastStarted: '1 days ago',
			lastActivity: 'today',
			portrait: images.avatar6,
			badges: [images.badge5, images.badge4, images.badge1],
			skills: [
				'Database',
				'Programming',
				'Critical Thinking',
				'Network',
				'Systems Thinking',
				'Technology Strategies',
				'Python',
				'Java',
			],
			roleFit: 'pending',
			assessments: {
				status: {
					versant: {
						reading: false,
						repeat: false,
						sentenceCompletion: false,
						passageReconstruction: false,
						openQuestion: false,
						total: 75,
					},
					talentLens: {
						infer: false,
						recognize: false,
						deduce: false,
						interpret: false,
						evaluateArguments: false,
						finish: false,
						performance: {
							initiative: 3.01,
							cooperation: 2.98,
							adaptability: 3.54,
							achievement: 4.28,
							tolerance: 4.52,
							persistence: 3.85,
							independence: 4.21,
						},
						total: 86,
					},
					DAR: {
						total: 92,
					},
				},
			},
		},
	],
};

export default userData;

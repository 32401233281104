/** @format */

import emailHeader from '../assets/images/email/top_bar.png';
import emailSideBar from '../assets/images/email/side_bar.png';
import emailActionBar from '../assets/images/email/mail_action_ba.png';
import emailFromBar from '../assets/images/email/from_bar.png';
import emailSidepanel from '../assets/images/email/side_panel.png';
import PearsonLogo from '../components/PearsonLogo';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const Email = () => {
	const navigate = useNavigate();

	const createAccount = () => {
		navigate(`${process.env.PUBLIC_URL}/signin/create`);
	};
	return (
		<div className='email-wrapper'>
			<header>
				<img
					src={emailHeader}
					alt=''
				/>
			</header>
			<aside>
				<img
					src={emailSideBar}
					alt=''
				/>
			</aside>
			<main>
				<img
					src={emailActionBar}
					alt=''
				/>
				<img
					src={emailFromBar}
					alt=''
				/>

				<div className='email-hero'>
					<div className='full-logo'>
						<PearsonLogo color={'light'} />
						<span className='logo-text'>
							360&deg; Talent Assessment Solutions
						</span>
					</div>

					<p className='small'>
						This email was automatically sent from Pearson Education
					</p>
					<h1>Dear Malik,</h1>
					<p>
						You have been invited by Pearson to complete an assessment as part
						of a recruitment process.
					</p>
				</div>

				<div className='email-body'>
					<h2>Before you start, please read this information carefully:</h2>
					<p>
						Make sure you are in a quiet room, free from distractions, have a
						good internet connection and that give yourself time to complete the
						assessment without being disturbed. You can use a desk-top computer,
						laptop, tablet or mobile phone. You should complete the assessment
						on your own without assistance from others. You may be sent another
						equivalent assessment at the later stages of the recruitment
						process. You will be given full instructions on how to complete the
						assessment once you have accessed the online website by clicking on
						the link below.
					</p>

					<h2>What will happen to your results?</h2>
					<p>
						Your results will remain strictly confidential and will only be seen
						by the Pearson_Marceau recruitment team. All results will be stored
						in accordance with the General Data Protection Regulation (GDPR). It
						is our policy is to store all assessment results for two years,
						after which they will be anonymous and used for monitoring and
						research purposes only. When you are ready to take the assessment,
						click the button below to get started:
					</p>

					<div className='action-bar'>
						<Button
							variant='primary'
							text='Get started'
							action={createAccount}
						/>
					</div>
				</div>
			</main>
			<img
				src={emailSidepanel}
				alt=''
			/>
		</div>
	);
};

export default Email;

/** @format */

import React from 'react';

const IconPencil = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill='none'
	>
		<path
			d='M21.5766 6.61312L17.3869 2.42437C17.0353 2.07286 16.5584 1.8754 16.0613 1.8754C15.5641 1.8754 15.0872 2.07286 14.7356 2.42437L3.17438 13.9847C2.99968 14.1583 2.86116 14.3649 2.76686 14.5924C2.67255 14.82 2.62434 15.064 2.62501 15.3103V19.5C2.62501 19.9973 2.82255 20.4742 3.17418 20.8258C3.52581 21.1775 4.00273 21.375 4.50001 21.375H20.25C20.5484 21.375 20.8345 21.2565 21.0455 21.0455C21.2565 20.8345 21.375 20.5484 21.375 20.25C21.375 19.9516 21.2565 19.6655 21.0455 19.4545C20.8345 19.2435 20.5484 19.125 20.25 19.125H11.7188L21.5766 9.26531C21.7508 9.09119 21.8889 8.88446 21.9832 8.65692C22.0775 8.42939 22.126 8.18551 22.126 7.93921C22.126 7.69292 22.0775 7.44904 21.9832 7.22151C21.8889 6.99397 21.7508 6.78724 21.5766 6.61312ZM12.75 7.59375L13.7813 8.625L7.12501 15.2812L6.09376 14.25L12.75 7.59375ZM4.87501 19.125V16.2187L6.33001 17.6728L7.78126 19.125H4.87501ZM9.75001 17.9062L8.71876 16.875L15.375 10.2187L16.4063 11.25L9.75001 17.9062ZM18 9.65625L14.3438 6L16.0631 4.28062L19.7194 7.93687L18 9.65625Z'
			fill={color}
		/>
	</svg>
);

export default IconPencil;

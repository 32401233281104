/** @format */
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import StickyFooter from '../components/Footer/StickyFooter';
import Sidebar from '../components/Sidebar';
import Button from '../components/Button';
import Select from '../components/Select';
import IconRight from '../components/icons/IconRight';

const RecruiterDashboard = (props) => {
	const jobList = useSelector((state) => state.users.jobProfiles);
	const navigate = useNavigate();

	//select options
	const showOptions1 = [
		{ value: 'Recruiter screened', label: 'Recruiter screened' },
		{ value: 'Recruiter not screened', label: 'Recruiter not screened' },
	];
	const showOptions2 = [
		{ value: 'Most recent activity', label: 'Most recent activity' },
		{ value: 'Oldest activity', label: 'Oldest activity' },
	];


	return (
		<div className='page-wrapper'>
			<Header fullLogo={'true'} />
			<Sidebar />

			<main className='recruiter-dashboard'>
				<section
					className='title'
					style={{ marginTop: '3.25rem' }}
				>
					<h1>Open job roles</h1>
					<Button
						variant='tertiary'
						text='Create role'
					/>
				</section>
				<section className='select-container'>
					<Select
						label='Show'
						name='show-select1'
						options={showOptions1}
					/>
					<Select
						label='Sorted by'
						name='show-select2'
						options={showOptions2}
					/>
				</section>
				<table>
					<thead>
						<tr>
							<th className='first-column'>Role</th>
							<th>Candidates</th>
							<th>In progress</th>
							<th>Not started</th>
						</tr>
					</thead>
					<tbody>
						{jobList?.map((job, index) => (
							<tr key={index}>
								<td
									onClick={() => {
										navigate(`/georgia/${job.id}`);
									}}
								>
									<p className='job-title'>{job.name}</p>
									<p className='posting-date'>
										Posted {job.applicants.inProgress.lastActivity}
									</p>
								</td>

								<td>
									<p className='bold-text'>{job.applicants.total} total</p>
									<p>{job.applicants.recommended.complete} high role fit
										{job.applicants.recommended.complete > 1 && `s`}
									</p>
								</td>

								<td>
									<p className='bold-text'>
										{job.applicants.inProgress.value} candidate{job.applicants.inProgress.value > 1 && `s`}
									</p>
									<p>{job.percent}% complete</p>
								</td>

								<td>
									<p className='bold-text'>
										{job.applicants.total -
											job.applicants.totalComplete -
											job.applicants.inProgress.value}{" "}
										candidate
										{job.applicants.total -
											job.applicants.totalComplete -
											job.applicants.inProgress.value > 1 && `s`}
									</p>
									<Link className='send-invite'>
										Send invite <IconRight size={14} />
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</main>
			<StickyFooter />
		</div>
	);
};

export default RecruiterDashboard;

/** @format */

import React from 'react';

const IconCheck = ({ size = 24, color = '#0D004D' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={size}
			height={size}
			viewBox={`0 0 24 24`}
			fill='none'
		>
			<path
				d='M21.796 7.54591L9.79596 19.5459C9.69144 19.6508 9.56725 19.734 9.4305 19.7908C9.29376 19.8476 9.14715 19.8768 8.99908 19.8768C8.85102 19.8768 8.70441 19.8476 8.56766 19.7908C8.43092 19.734 8.30672 19.6508 8.20221 19.5459L2.95221 14.2959C2.84756 14.1913 2.76455 14.067 2.70792 13.9303C2.65128 13.7936 2.62213 13.647 2.62213 13.499C2.62213 13.351 2.65128 13.2045 2.70792 13.0678C2.76455 12.931 2.84756 12.8068 2.95221 12.7022C3.05685 12.5975 3.18109 12.5145 3.31782 12.4579C3.45455 12.4012 3.60109 12.3721 3.74908 12.3721C3.89708 12.3721 4.04362 12.4012 4.18035 12.4579C4.31708 12.5145 4.44131 12.5975 4.54596 12.7022L9.00002 17.1562L20.2041 5.95404C20.4154 5.74269 20.7021 5.62396 21.001 5.62396C21.2998 5.62396 21.5865 5.74269 21.7978 5.95404C22.0092 6.16538 22.1279 6.45203 22.1279 6.75091C22.1279 7.0498 22.0092 7.33644 21.7978 7.54779L21.796 7.54591Z'
				fill={color}
			/>
		</svg>
	);
};

export default IconCheck;

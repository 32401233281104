/** @format */

const LogoDAT = ({ width = 85, height = 30, color = '#512EAB' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
		>
			<path
				d='M72.9103 30.1203V4.66385H65.0596V1.16602H84.492V4.66385H76.7579V30.1203H72.9103Z'
				fill={color}
			/>
			<path
				d='M42.8262 30.1203L53.2031 1.16602H57.7114L68.0883 30.1203H64.0075L61.6368 23.319H49.2777L46.907 30.1203H42.8262ZM50.4825 19.8211H60.4319L54.9131 3.92542H56.0014L50.4825 19.8211Z'
				fill={color}
			/>
			<path
				d='M19.9375 30.1203V1.16602H29.4205C32.3483 1.16602 34.9005 1.76194 37.0769 2.9538C39.2533 4.14565 40.9375 5.8298 42.1293 8.00623C43.3212 10.1567 43.9171 12.6959 43.9171 15.6237C43.9171 18.5256 43.3212 21.0648 42.1293 23.2412C40.9375 25.4177 39.2533 27.1148 37.0769 28.3325C34.9005 29.5244 32.3483 30.1203 29.4205 30.1203H19.9375ZM23.7463 26.6225H29.4594C31.6099 26.6225 33.4625 26.1691 35.0171 25.2622C36.5976 24.3554 37.8153 23.0858 38.6704 21.4535C39.5254 19.7952 39.9529 17.852 39.9529 15.6237C39.9529 13.3696 39.5124 11.4263 38.6315 9.79401C37.7765 8.16169 36.5587 6.90506 34.9782 6.02412C33.4236 5.11728 31.584 4.66385 29.4594 4.66385H23.7463V26.6225Z'
				fill={color}
			/>
		</svg>
	);
};

export default LogoDAT;

/** @format */

import IconCheck from './icons/IconCheck';
import IconX from './icons/IconX';

const Alert = (props) => {
	return (
		<div
			className={`alert animate__animated animate__fadeInDown ${props.type}`}
		>
			<button
				onClick={(e) => props.dismissAlert(e)}
				className='close-btn'
			>
				<IconX size='16' />
			</button>
			{props.icon ? props.icon : <IconCheck />}
			<p>{props.message}</p>
		</div>
	);
};

export default Alert;

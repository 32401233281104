import React, { useEffect, useState } from 'react';

const Score = ({ score, label }) => {

    const [progress, setProgress] = useState(0);
    const radius = 60;
    const strokeWidth = 9;
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = 2 * Math.PI * normalizedRadius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    useEffect(() => {
        setProgress(score);
    }, [score]);


    return (
        <div className="score-chart">
            <div className="chart">
                <svg height={radius * 2} width={radius * 2}>
                    <circle
                        className="background"
                        strokeWidth={strokeWidth}
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                    <circle
                        className="progress"
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference + ' ' + circumference}
                        style={{ strokeDashoffset }}
                        strokeLinecap="round"
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                </svg>
                <div className="score-percent">
                    <span>{progress}%</span>
                </div>
            </div>

            <div className="score-label">
                <span>{label}</span>
            </div>
        </div>

    );
};

export default Score;

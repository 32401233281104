import React from 'react';

const Select = ({ label, name, options }) => {
    return (
        <div className='select-wrapper'>
            <label htmlFor={name}>{label}</label>
            <select id={name} name={name}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;

/** @format */

import imgComplete from '../../assets/images/icon_complete_alt.svg';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const AssessmentComplete = () => {
	const { width, height } = useWindowSize();
	return (
		<>
			<section className='assessment-complete'>
				<img
					src={imgComplete}
					alt=''
					width={'266'}
				/>

				<h1>Test Complete</h1>
				<h2>Congratulations!</h2>
				<p>To submit your answers, click Finish.</p>
			</section>
			<Confetti
				width={width}
				height={height}
				recycle={false}
				numberOfPieces={1000}
				tweenDuration={10000}
			/>
		</>
	);
};

export default AssessmentComplete;

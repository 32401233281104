/** @format */

import IconHeadphones from './icons/IconHeadphones';

const Voice = (props) => {
	let volHeight = Math.round(
		(props.volume / 100) * 72
	); /* 72px is the height of the volume control track*/
	return (
		<div className='voice-control'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='8'
				height='78'
				viewBox='0 0 8 78'
				fill='none'
			>
				<circle
					cx='4'
					cy='74'
					r='4'
					transform='rotate(-90 4 74)'
					fill='#0D004D'
				/>
				<circle
					cx='4'
					cy='60'
					r='4'
					transform='rotate(-90 4 60)'
					fill='#0D004D'
				/>
				<circle
					cx='4'
					cy='46'
					r='4'
					transform='rotate(-90 4 46)'
					fill='#0D004D'
				/>
				<circle
					cx='4'
					cy='32'
					r='4'
					transform='rotate(-90 4 32)'
					fill='#C2C0FF'
				/>
				<circle
					cx='4'
					cy='18'
					r='4'
					transform='rotate(-90 4 18)'
					fill='#C2C0FF'
				/>
				<circle
					cx='4'
					cy='4'
					r='4'
					transform='rotate(-90 4 4)'
					fill='#C2C0FF'
				/>
			</svg>

			<IconHeadphones />
		</div>
	);
};

export default Voice;

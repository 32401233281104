import React from 'react';
import userFemale from '../assets/images/avatar/georgia.png';
import userMale from '../assets/images/avatar/malik.png';

const Avatar = ({ userType }) => {

    return (
        <div >
            <img
                style={{ width: '40px' }}
                src={userType === 'female' ? userFemale : userMale}
                alt={userType === 'female' ? "user type female, Georgia" : "user type male, Malik"}
            />
        </div>
    );
};

export default Avatar;

/** @format */

const displaySvgIcon = (name) => {
  // console.log("Name:", name);
  switch (name) {

    case "question-mark":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 17C12.6213 17 13.125 17.5037 13.125 18.125C13.125 18.7463 12.6213 19.25 12 19.25C11.3787 19.25 10.875 18.7463 10.875 18.125C10.875 17.5037 11.3787 17 12 17ZM12.5498 5.27618C14.3822 5.43203 15.75 7.0198 15.75 8.77898C15.75 10.0945 15.1964 11.3231 14.2528 12.3062L14.0589 12.4995C13.4348 13.0952 13.0678 13.7837 13.0096 14.4748L13.0015 14.6633V15C13.0015 15.5523 12.5537 16 12.0015 16C11.4886 16 11.066 15.614 11.0082 15.1166L11.0015 15L11.0015 14.6609C11.0045 13.4019 11.5512 12.2144 12.4859 11.2439L12.6779 11.0527C13.3649 10.3971 13.75 9.59954 13.75 8.77898C13.75 8.03203 13.149 7.33437 12.3803 7.26899C11.4904 7.1933 10.6747 7.66784 10.2722 8.5093L10.2016 8.67132L10.1803 8.72542C9.97769 9.2392 9.39695 9.49146 8.88316 9.28886C8.40608 9.10074 8.15449 8.58656 8.28319 8.1027L8.31971 7.99174L8.34104 7.93765C9.03514 6.17746 10.7194 5.1205 12.5498 5.27618Z" fill="#0D004D" />
        </svg>
      );
  }
};

export default displaySvgIcon;

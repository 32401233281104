/** @format */

const PearsonLogo = (props) => {
	const fillColor = props.color === 'light' ? '#ffffff' : '#0D004D';
	return (
		<>
			{/*<svg
				width='29'
				height='24'
				viewBox='0 0 29 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M11.8253 0H19.7412C24.3644 0 27.4574 3.1941 27.4574 7.45435C27.4574 11.7146 24.3644 14.9087 19.7412 14.9087H15.8164V23.2943H11.8247V0H11.8253ZM23.4002 7.45435C23.4002 5.25833 21.9696 3.79369 19.2421 3.79369H15.8164V11.115H19.2421C21.9696 11.115 23.4002 9.65037 23.4002 7.45435Z'
					fill={fillColor}
				/>
				<path
					d='M6.53174 2.59449V2.5982C6.81253 5.38763 6.96838 8.44189 6.96838 11.644C6.96838 14.8462 6.81315 17.9004 6.53174 20.6899V20.6936C8.76197 18.3255 10.13 15.145 10.13 11.6447V11.6428C10.13 8.14302 8.76197 4.96253 6.53174 2.59387V2.59449Z'
					fill={fillColor}
				/>
				<path
					d='M6.96902 11.6441C6.96902 6.61591 4.14999 2.24367 0 0C1.26788 2.28141 2.12448 6.64128 2.12448 11.6441C2.12448 16.6468 1.26788 21.0079 0 23.2894C4.14999 21.0469 6.96902 16.6734 6.96902 11.6453V11.6441Z'
					fill={fillColor}
				/>
			</svg> */}

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='107'
				height='24'
				viewBox='0 0 107 24'
				fill='none'
			>
				<path
					d='M0.000623829 0H7.9894C12.6551 0 15.7766 3.22197 15.7766 7.51939C15.7766 11.8168 12.6551 15.0388 7.9894 15.0388H4.0284V23.4976H0V0H0.000623829ZM11.682 7.51939C11.682 5.30421 10.2383 3.8268 7.48569 3.8268H4.0284V11.212H7.48569C10.2383 11.212 11.682 9.73458 11.682 7.51939Z'
					fill='white'
				/>
				<path
					d='M61.5553 18.1926C61.5553 18.1926 63.6706 20.4752 66.3551 20.4752C68.0005 20.4752 69.3767 20.0058 69.3767 19.0315C69.3767 17.991 68.6384 17.7894 66.2216 17.2857C63.0658 16.6147 60.3132 15.2721 60.3132 12.2505C60.3132 9.76629 62.7974 7.28271 67.1616 7.28271C70.753 7.28271 73.1368 9.22951 73.1368 9.22951L71.1232 12.1837C71.1232 12.1837 69.3112 10.8074 67.1622 10.8074C65.6523 10.8074 64.276 11.3448 64.276 12.3179C64.276 13.291 65.3502 13.5263 68.0354 14.0974C71.1906 14.7684 73.4395 15.8095 73.4395 19.0989C73.4395 21.8178 70.5533 23.9999 66.4912 23.9999C61.8255 23.9999 59.3076 21.1137 59.3076 21.1137L61.5565 18.1932H61.5553V18.1926Z'
					fill='white'
				/>
				<path
					d='M90.8746 15.6405C90.8746 20.3062 87.1152 23.9988 82.3821 23.9988C77.649 23.9988 73.8896 20.3062 73.8896 15.6405C73.8896 10.9748 77.649 7.28223 82.3821 7.28223C87.1152 7.28223 90.8746 10.9411 90.8746 15.6405ZM86.78 15.6405C86.78 13.1563 85.1016 11.0416 82.3821 11.0416C79.6626 11.0416 77.9848 13.1563 77.9848 15.6405C77.9848 18.1247 79.6626 20.2394 82.3821 20.2394C85.1016 20.2394 86.78 18.1247 86.78 15.6405Z'
					fill='white'
				/>
				<path
					d='M48.479 15.5411C48.479 15.5037 48.4765 15.4669 48.4765 15.4294C48.4765 15.4294 48.4765 15.3395 48.474 15.3395C48.3685 10.7375 44.6515 7.18286 39.9871 7.18286C35.3227 7.18286 31.4946 10.8418 31.4946 15.5411C31.4946 17.9954 32.5882 20.3585 34.475 21.9314C36.5704 23.6778 39.8236 24.5879 42.3733 23.3102C43.151 22.9207 43.9537 22.2965 44.3844 21.5195V23.4968H48.4765V15.626C48.4765 15.5973 48.479 15.5699 48.479 15.5418V15.5411ZM39.9865 20.1407C37.2676 20.1407 35.5886 18.026 35.5886 15.5418C35.5886 13.0576 37.2663 10.9429 39.9865 10.9429C42.7066 10.9429 44.3838 13.0576 44.3838 15.5418C44.3838 18.026 42.706 20.1407 39.9865 20.1407Z'
					fill='white'
				/>
				<path
					d='M60.7467 7.6873L59.3367 11.3125C59.3367 11.3125 58.5977 10.943 57.423 10.943C54.9725 10.943 53.8658 13.0914 53.8658 13.0914V23.4976H49.8374V7.58618H53.6642V9.90248C53.6642 9.90248 55.0405 7.18359 57.9941 7.18359C59.4378 7.18359 60.7467 7.68668 60.7467 7.68668V7.6873Z'
					fill='white'
				/>
				<path
					d='M106.188 14.0282C106.188 10.041 102.955 6.80908 98.9688 6.80908C94.9822 6.80908 91.749 10.041 91.749 14.0282C91.749 14.2598 91.7858 23.5968 91.7858 23.5968H95.8143C95.8143 23.5968 95.8205 15.8951 95.8274 15.0731C95.8336 14.3416 95.783 13.4802 96.082 12.808C96.592 11.6633 97.7036 10.8918 98.9694 10.8918C99.9151 10.8918 100.79 11.325 101.395 12.0421C102.073 12.8448 102.109 13.8965 102.107 14.9021C102.101 17.8026 102.125 20.6981 102.125 23.5962H106.153C106.153 23.5962 106.19 14.2592 106.19 14.0276H106.188V14.0282Z'
					fill='white'
				/>
				<path
					d='M26.2165 18.6995C25.5779 19.5502 24.3246 20.2406 22.703 20.2406C20.1183 20.2406 18.9436 18.4274 18.8088 17.0186H30.6194C30.6886 16.5648 30.7261 16.0942 30.7261 15.6086C30.7261 15.3065 30.7261 14.7023 30.6924 14.0981C30.1519 9.84875 26.7033 7.18042 22.7036 7.18042C18.1053 7.18042 14.7155 10.873 14.7148 15.5387C14.7148 17.4087 15.4539 19.0952 15.452 19.1402C16.6897 21.9708 19.4317 23.8989 22.7548 23.8989C26.0779 23.8989 29.1775 21.8765 30.3478 18.6989H26.2177L26.2165 18.6995ZM22.6624 10.7838C24.7865 10.7838 26.5079 12.1988 26.5079 13.9439H18.8181C18.8181 12.1981 20.5396 10.7838 22.6624 10.7838Z'
					fill='white'
				/>
			</svg>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='9'
				height='20'
				viewBox='0 0 9 20'
				fill='none'
			>
				<path
					d='M5.81787 2.25175V2.25495C6.06109 4.65673 6.1961 7.28655 6.1961 10.0437C6.1961 12.8008 6.06163 15.4307 5.81787 17.8324V17.8356C7.74972 15.7967 8.93475 13.0582 8.93475 10.0442V10.0426C8.93475 7.02921 7.74972 4.29071 5.81787 2.25122V2.25175Z'
					fill='#AD92F5'
				/>
				<path
					d='M6.16348 9.99947C6.16348 5.68149 3.74694 1.92678 0.189453 0C1.27631 1.95919 2.01061 5.70328 2.01061 9.99947C2.01061 14.2957 1.27631 18.0408 0.189453 20C3.74694 18.0743 6.16348 14.3185 6.16348 10.0005V9.99947Z'
					fill='#AD92F5'
				/>
			</svg>
		</>
	);
};
export default PearsonLogo;

/** @format */

import IconCaretDown from '../icons/IconCaretDown';
import Accordion from '../Accordion';

const Instructions = (props) => {
	const faqs = [
		{
			question: 'How does this work?',
			answer:
				"You'll be asked to answer a series of questions that will help provide insight to your potential employer. Depending on the employer, you may also get a copy of your results.",
		},
		{
			question: 'What happens to my data?',
			answer:
				"Your results are fully confidential and will only be shared with your prospective employer. For mode details, see Pearson's privacypolicy",
		},
		{
			question: 'What if I need an accomodation?',
			answer:
				'Check your email and contact the company or individual who originally sent you the assessment. They should be able to help!',
		},
	];
	return (
		<section className='instructions'>
			<h1>Instructions</h1>

			<p>
				This assessment contains five subtests designed to find out how well you
				are able to reason analytically and logically. Each subtest has separate
				directions. Please read them carefully.
			</p>
			<p>
				For each question, decide what you think is the best answer. As your
				score will depend on the number of items you answer correctly, try to
				answer each question even if you are not sure that your answer is
				correct. <strong>This test contains 40 scored questions.</strong>
			</p>
			<p>
				By clicking the Next Page button below, you agree to our honesty
				contract:
			</p>
			<ul>
				<li>You will take this test alone, without assistance from others.</li>
				<li>
					You will take this test for the sole purpose of wanting to perform
					well on the test.
				</li>
				<li>You will respect the copyright of the test contents.</li>
			</ul>
			<p>
				Click <strong>Start Test</strong> to begin the test.
			</p>

			<h2>FAQs</h2>

			{faqs.map((item, i) => {
				return (
					<Accordion
						key={`acc${i}`}
						question={item.question}
						answer={item.answer}
					/>
				);
			})}
		</section>
	);
};

export default Instructions;

/** @format */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconRight from '../components/icons/IconRight';
import Button from '../components/Button';
import Header from '../components/Header';
import Volume from '../components/Volume';
import Voice from '../components/Voice';
import AssessmentDetails from '../components/assessments/AssessmentDetails';

import { updateVersantStatus } from '../redux/slices/versantSlice';
import { store } from '../redux/store';
import {
	getCandidates,
	updateAssessmentStatus,
	updateInterviewSteps,
} from '../redux/slices/userSlice';

import { useDispatch, useSelector, getState } from 'react-redux';
import IconLeft from '../components/icons/IconLeft';

const Versant = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const candidates = useSelector(getCandidates).payload.users.candidates;

	const [stepDetails, setStepDetails] = useState([
		{ name: 'Reading', status: 'current', key: 'readingItems' },
		{ name: 'Repeat', status: '', key: 'repeatItems' },
		{ name: 'Sentence Completion', status: '', key: 'sentenceCompletionItems' },
		{ name: 'Reconstruction', status: '', key: 'passageReconstructionItems' },
		{ name: 'Open Questions', status: '', key: 'openQuestionItems' },
		{ name: 'Finish', status: '', key: 'completion' },
	]);

	useEffect(() => {
		var currStep = parseInt(localStorage.getItem('versantStep'));
		var tempSteps = stepDetails;
		tempSteps.forEach((item, i) => {
			if (i < currStep - 1) {
				item.status = 'completed';
			} else if (i === currStep - 1) {
				item.status = 'current';
			}
		});
		//console.log(tempSteps);
		setStepDetails([...tempSteps]);
	}, []);

	const [step, setStep] = useState(
		parseInt(localStorage.getItem('versantStep')) || 0
	);
	const startAssessment = () => {
		setStep(1);
	};

	const previousStep = () => {
		var tempSteps = stepDetails;
		if (tempSteps[step - 1].status === 'current') {
			tempSteps[step - 1].status = '';
		}
		if (tempSteps[step - 2] !== undefined) {
			tempSteps[step - 2].status = 'current';
		}

		setStep(step - 1);
		setStepDetails(tempSteps);
	};
	const nextStep = () => {
		var tempSteps = stepDetails;
		tempSteps[step - 1].status = 'completed';
		if (tempSteps[step] !== undefined) {
			tempSteps[step].status = 'current';
		}

		var thisStepName = tempSteps[step - 1].key.split('Items')[0];

		setStep((step) => step + 1);
		setStepDetails([...tempSteps]);

		dispatch(
			updateVersantStatus({
				key: tempSteps[step - 1].key,
				status: 'completed',
			})
		);
		dispatch(
			updateAssessmentStatus({
				userId: 'malik',
				assessmentType: 'versant',
				subcategory: thisStepName,
				status: true,
			})
		);
	};

	const completeAssessment = () => {
		dispatch(updateInterviewSteps({ userId: 'malik' }));
		const updatedCandidates = store.getState().users.candidates;

		if (step < stepDetails.length - 1) {
			localStorage.setItem('versantStep', step);
		} else {
			localStorage.removeItem('versantStep');
			localStorage.setItem('candidates', JSON.stringify(updatedCandidates));
		}

		navigate(`${process.env.PUBLIC_URL}/malik`);
	};

	return (
		<div className='assessment-wrapper'>
			{step === 0 ? (
				<Header logo='versant' />
			) : (
				<Header
					logo='versant'
					steps={stepDetails}
					action={'Save and exit'}
					btnAction={completeAssessment}
				/>
			)}

			<main>
				<AssessmentDetails step={step} />

				{step > 0 && (
					<div className='controls'>
						<Volume volume='50' />
						<Voice />
					</div>
				)}
			</main>
			<footer>
				{step === 0 ? (
					<Button
						variant='primary'
						text='Start Test'
						icon={<IconRight color='#fff' />}
						iconPosition='after'
						action={startAssessment}
					/>
				) : step === 6 ? (
					<>
						<Button
							variant='secondary'
							text='Previous Page'
							icon={<IconLeft />}
							action={previousStep}
						/>
						<Button
							variant='primary'
							text='Finish'
							action={completeAssessment}
						/>
					</>
				) : (
					<>
						<Button
							variant='secondary'
							text='Previous Page'
							icon={<IconLeft />}
							action={previousStep}
						/>
						<Button
							variant='primary'
							text='Next Page'
							icon={<IconRight color='#fff' />}
							iconPosition='after'
							action={nextStep}
						/>
					</>
				)}
			</footer>
		</div>
	);
};

export default Versant;

/** @format */

import React from 'react';

const StickyFooter = (props) => {
	return (
		<footer className={`footer ${props.sticky ? 'is-sticky' : ''}`}>
			{/* Add any content you want inside the footer here */}
			<ul className='footer-list'>
				<li>Terms of use</li>
				<li>Privacy</li>
				<li>Do not sell my personal informational</li>
				<li>Acessibility</li>
				<li>Patent notice</li>
			</ul>
			<p>&copy; 1996-2024 Pearson All rights reserved.</p>
		</footer>
	);
};

export default StickyFooter;

/** @format */
import imgListen from '../../assets/images/img_voice2.png';
import imgRepeat from '../../assets/images/img_voice.png';

const OpenQuestionsItems = (props) => {
	return (
		<section className='assessment-question'>
			<h1>5. Open Questions</h1>

			<p>{props.data.instructions}</p>

			<div className='item-details'>
				<span>Question: 1 of 16</span> | <span>Time Left: 0:25</span>
			</div>
			<div className='listen-repeat'>
				<div>
					<p>Please listen</p>
					<div className='listen'>
						<img
							src={imgListen}
							alt=''
						/>
					</div>
				</div>
				<div>
					<p>Answer the question</p>
					<div className='repeat'>
						<img
							src={imgRepeat}
							alt=''
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
export default OpenQuestionsItems;

/** @format */

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import userReducer from './slices/userSlice';
import updateRecruiterAuthReducer from './slices/userSlice';
import versantReducer from './slices/versantSlice';
import talentLensReducer from './slices/talentLensSlice';
import logger from 'redux-logger';
import { thunk } from 'redux-thunk';

/************************************************
 * Add reducer functions to store and export store
 *  https://www.dhiwise.com/post/how-to-use-redux-persist-in-react-applications
 *************************************************/

const persistConfig = {
	key: 'root',
	storage,
	// Optionally, you can whitelist or blacklist specific reducers
	whitelist: ['', ''],
	blacklist: [''],
};

const rootReducer = combineReducers({
	users: userReducer,
	versant: versantReducer,
	talentLens: talentLensReducer,
	updateRecruiterAuth: updateRecruiterAuthReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const middleware = [thunk, logger];

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	// middleware: [thunk, logger],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(thunk, logger),
});

const persistor = persistStore(store);

export { store, persistor };

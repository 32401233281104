/** @format */

import React, { useState } from 'react';

function Radio({ options, onChange }) {
	const [selectedOption, setSelectedOption] = useState(null);

	const handleRadio = (event) => {
		setSelectedOption(event.target.value);
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<div className='radio-container'>
			{options.map((option, index) => (
				<label key={index}>
					<input
						type='radio'
						value={option}
						checked={selectedOption === option}
						onChange={handleRadio}
					/>
					{option}
				</label>
			))}
		</div>
	);
}

export default Radio;

/** @format */

import { useEffect, useState } from 'react';
import IconRight from '../icons/IconRight';

const ReadingItems = (props) => {
	var [selectedItem, setSelectedItem] = useState(-1);
	useEffect(() => {
		const itemCount = props.data.sentences.length - 1;
		var timer = setInterval(() => {
			if (selectedItem < itemCount) {
				setSelectedItem((selectedItem) => selectedItem + 1);
			} else {
				clearInterval(timer);
			}
		}, 3000);
	}, []);
	return (
		<section className='assessment-question'>
			<h1>1. Reading</h1>
			<p>{props.data.instructions}</p>
			<ul className='reading-items'>
				{props.data.sentences.map((element, i) => {
					return (
						<li
							key={`senetence${i}`}
							className={`${selectedItem === i ? 'selected' : ''}`}
						>
							<IconRight /> {element}
						</li>
					);
				})}
			</ul>
		</section>
	);
};
export default ReadingItems;

/** @format */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import IconItems from '../components/icons/IconItems';
import IconUnlocked from '../components/icons/IconUnlocked';
import IconLocked from '../components/icons/IconLocked';
import IconTimer from '../components/icons/IconTimer';
import Button from '../components/Button';
import LogoVersant from '../components/icons/LogoVersant';
import LogoDAT from '../components/icons/LogoDAT';
import StickyFooter from '../components/Footer/StickyFooter';
import LogoWatsonGlaser from '../components/icons/LogoWatsonGlaser';
import {
	getCandidates,
	updateCandidate,
	updateRecruiterAuth,
} from '../redux/slices/userSlice';
import ImgComplete from '../components/icons/ImgComplete';


const CandidateDashboard = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const candidates = useSelector((state) => state.users.candidates);

	const [versantStatus, setVersantStatus] = useState(false);
	const [watsonGlaserStatus, setWatsonGlaserStatus] = useState(false);

	//Reset authenticated
	useEffect(() => {
		var thisCandidate = candidates.filter((a) => a.userID === 'malik');

		/* Check of assessments are complete */
		const versantAssessment = thisCandidate[0].assessments.status.versant;
		const watsonGlaserAssessment =
			thisCandidate[0].assessments.status.talentLens;
		if (
			localStorage.getItem('versantStep') > 0 &&
			localStorage.getItem('versantStep') < 6
		) {
			setVersantStatus('started');
		} else if (
			versantAssessment.reading &&
			versantAssessment.repeat &&
			versantAssessment.sentenceCompletion &&
			versantAssessment.passageReconstruction &&
			versantAssessment.openQuestion
		) {
			setVersantStatus(true);
		} else {
			setVersantStatus(false);
		}
		if (
			watsonGlaserAssessment.infer &&
			watsonGlaserAssessment.recognize &&
			watsonGlaserAssessment.deduce &&
			watsonGlaserAssessment.interpret &&
			watsonGlaserAssessment.evaluateArguments
		) {
			setWatsonGlaserStatus(true);
		}

		dispatch(
			updateCandidate({
				authenticated: false,
			})
		);

		dispatch(updateRecruiterAuth({ authenticated: false }));
	}, []);

	const beginAssessment = (assessmentName) => {
		navigate(`${process.env.PUBLIC_URL}/malik/${assessmentName}`);
	};

	return (
		<div className='candidate-dashboard'>
			<Header
				fullLogo={'true'}
				variant='gradient'
			/>

			<main>
				<h1>Welcome, Malik!</h1>
				<p className='page-prompt'>
					Ready to get started? Make sure you're in a distraction-free
					environment with a strong internet connection.
				</p>

				<section className='available-assessments'>
					<div className='card-wrapper'>
						<div className='card'>
							<LogoVersant
								color={'#512EAB'}
								width={124}
								height={30}
							/>

							{versantStatus === true ? (
								<>
									<p>Thank you for completing this assessment! Your results were sent to the recruiter to be evaluated.</p>
									<ImgComplete />
								</>
							) : (
								<>
									<p>
										This test will measure your ability in speaking, listening,
										reading and writing the English language.
									</p>
									<ul className='assessment-data'>
										<li>
											<div className='icon-wrap'>
												<IconTimer />
											</div>
											60 min
										</li>
										<li>
											<div className='icon-wrap'>
												<IconItems />
											</div>
											40 items
										</li>
										<li>
											<div className='icon-wrap'>
												<IconUnlocked />
											</div>
											Flexible
										</li>
									</ul>

									{versantStatus === 'started' ? (
										<Button
											text={'Continue assessment'}
											variant='tertiary'
											action={() => beginAssessment('versant')}
										/>
									) : (
										<Button
											text={'Begin assessment'}
											variant='primary'
											action={() => beginAssessment('versant')}
										/>
									)}
								</>
							)}
						</div>
						{versantStatus === 'started' && (
							<div className="status-bar">
								<div className="status-progress"></div>
							</div>
						)}
					</div>

					<div className='card-wrapper'>
						<div className='card'>
							<LogoWatsonGlaser color={'#512EAB'} />
							{watsonGlaserStatus ? (
								<>
									<p>Thank you for completing this assessment! Your results were sent to the recruiter to be evaluated.</p>
									<ImgComplete />
								</>
							) : (
								<>
									<p>
										This test will measure your ability to analyze, reason, interpret
										and draw logical conclusions.
									</p>
									<ul className='assessment-data'>
										<li>
											<div className='icon-wrap'>
												<IconTimer />
											</div>
											30 min
										</li>
										<li>
											<div className='icon-wrap'>
												<IconItems />
											</div>
											40 Items
										</li>
										<li>
											<div className='icon-wrap'>
												<IconLocked />
											</div>
											Timed
										</li>
									</ul>
									<Button
										text={'Begin assessment'}
										variant='primary'
										action={() => beginAssessment('watsonGlaser')}
									/>
								</>
							)}
						</div>
					</div>

					<div className='card-wrapper'>
						<div className='card'>
							<LogoDAT color={'#512EAB'} />
							<p>
								This test will measure verbal analogies, numerical sequences, and
								abstract reasoning.
							</p>
							<ul className='assessment-data'>
								<li>
									<div className='icon-wrap'>
										<IconTimer />
									</div>
									25 min
								</li>
								<li>
									<div className='icon-wrap'>
										<IconItems />
									</div>
									15 items
								</li>
								<li>
									<div className='icon-wrap'>
										<IconUnlocked />
									</div>
									Flexible
								</li>
							</ul>
							<Button
								text={'Begin assessment'}
								variant='primary'
							/>
						</div>
					</div>
				</section>
			</main>
			<StickyFooter />
		</div>
	);
};

export default CandidateDashboard;

/** @format */
import Instructions from './Instructions';
import ReadingItems from './ReadingItems';
import SentenceCompletionItems from './SentenceCompletionItems';
import RepeatItems from './RepeatItems';
import ReconstuctionItems from './ReconstuctionItems';
import OpenQuestionsItems from './OpenQuestionsItems';
import AssessmentComplete from './AssessmentComplete';
import versantData from '../../redux/data/versantData';

const AssessmentDetails = ({ step }) => {
	return (
		<>
			{step == 0 ? (
				<Instructions />
			) : step == 1 ? (
				<ReadingItems data={versantData.readingItems[0]} />
			) : step == 2 ? (
				<RepeatItems data={versantData.repeatItems[0]} />
			) : step == 3 ? (
				<SentenceCompletionItems
					data={versantData.sentenceCompletionItems[0]}
				/>
			) : step == 4 ? (
				<ReconstuctionItems data={versantData.passageReconstructionItems[0]} />
			) : step == 5 ? (
				<OpenQuestionsItems data={versantData.openQuestionItems[0]} />
			) : (
				<AssessmentComplete />
			)}
		</>
	);
};

export default AssessmentDetails;

/** @format */

import React from 'react';

const IconDoor = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M21.75 19.875H19.875V3.75C19.875 3.25272 19.6775 2.77581 19.3258 2.42417C18.9742 2.07254 18.4973 1.875 18 1.875H6C5.50272 1.875 5.02581 2.07254 4.67417 2.42417C4.32254 2.77581 4.125 3.25272 4.125 3.75V19.875H2.25C1.95163 19.875 1.66548 19.9935 1.4545 20.2045C1.24353 20.4155 1.125 20.7016 1.125 21C1.125 21.2984 1.24353 21.5845 1.4545 21.7955C1.66548 22.0065 1.95163 22.125 2.25 22.125H21.75C22.0484 22.125 22.3345 22.0065 22.5455 21.7955C22.7565 21.5845 22.875 21.2984 22.875 21C22.875 20.7016 22.7565 20.4155 22.5455 20.2045C22.3345 19.9935 22.0484 19.875 21.75 19.875ZM17.625 19.875H16.125V4.125H17.625V19.875ZM6.375 4.125H13.875V19.875H6.375V4.125ZM12.75 12C12.75 12.2967 12.662 12.5867 12.4972 12.8334C12.3324 13.08 12.0981 13.2723 11.824 13.3858C11.5499 13.4994 11.2483 13.5291 10.9574 13.4712C10.6664 13.4133 10.3991 13.2704 10.1893 13.0607C9.97956 12.8509 9.8367 12.5836 9.77882 12.2926C9.72094 12.0017 9.75065 11.7001 9.86418 11.426C9.97771 11.1519 10.17 10.9176 10.4166 10.7528C10.6633 10.588 10.9533 10.5 11.25 10.5C11.6478 10.5 12.0294 10.658 12.3107 10.9393C12.592 11.2206 12.75 11.6022 12.75 12Z' />
	</svg>
);

export default IconDoor;

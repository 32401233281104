/** @format */

import { useNavigate } from 'react-router-dom';
import IconArrowLeft from './icons/IconArrowLeft';

const BackBtn = (props) => {
	const navigate = useNavigate();
	const onClickBack = (e) => {
		e.preventDefault();
		navigate(-1);
	};

	return (
		<a
			href='!#'
			onClick={(e) => onClickBack(e)}
			className='back-btn'
		>
			<IconArrowLeft
				size={16}
				color='#0D004D'
			/>{' '}
			{props.text}
		</a>
	);
};

export default BackBtn;

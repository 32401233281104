/** @format */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconRight from '../components/icons/IconRight';
import Button from '../components/Button';
import Header from '../components/Header';
import Volume from '../components/Volume';
import Voice from '../components/Voice';

import { updateTalentLensStatus } from '../redux/slices/talentLensSlice';
import { store } from '../redux/store';
import {
	updateAssessmentStatus,
	updateInterviewSteps,
} from '../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import IconLeft from '../components/icons/IconLeft';
import WatsonGlaserDetails from '../components/assessments/WatsonGlaserDetails';

const WatsonGlaser = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [step, setStep] = useState(0);

	const [stepDetails, setStepDetails] = useState([
		{ name: 'Infer', status: 'current', key: 'inferItems' },
		{ name: 'Recognize', status: '', key: 'recognizeItems' },
		{ name: 'Deduce', status: '', key: 'deduceItems' },
		{ name: 'Interpret', status: '', key: 'interpretItems' },
		{ name: 'Evaluate Arguments', status: '', key: 'evaluateArguments' },
		{ name: 'Finish', status: '', key: 'completion' },
	]);

	const startAssessment = () => {
		setStep(1);
	};

	const previousStep = () => {
		var tempSteps = stepDetails;
		if (tempSteps[step - 1].status === 'current') {
			tempSteps[step - 1].status = '';
		}
		if (tempSteps[step - 2] !== undefined) {
			tempSteps[step - 2].status = 'current';
		}

		setStep(step - 1);
		setStepDetails(tempSteps);
	};
	const nextStep = () => {
		var tempSteps = stepDetails;
		console.log(tempSteps);
		tempSteps[step - 1].status = 'completed';
		if (tempSteps[step] !== undefined) {
			tempSteps[step].status = 'current';
		}

		var thisStepName = tempSteps[step - 1].key.split('Items')[0];

		setStep(step + 1);
		setStepDetails(tempSteps);

		dispatch(
			updateTalentLensStatus({
				key: tempSteps[step - 1].key,
				status: 'completed',
			})
		);
		dispatch(
			updateAssessmentStatus({
				userId: 'malik',
				assessmentType: 'talentLens',
				subcategory: thisStepName,
				status: true,
			})
		);
	};

	const completeAssessment = () => {
		dispatch(updateInterviewSteps({ userId: 'malik' }));
		const updatedCandidates = store.getState().users.candidates;
		localStorage.setItem('candidates', JSON.stringify(updatedCandidates));

		navigate(`${process.env.PUBLIC_URL}/malik`);
	};

	return (
		<div className='assessment-wrapper'>
			{step === 0 ? (
				<Header logo='watson' />
			) : (
				<Header
					logo='watson'
					steps={stepDetails}
				/>
			)}

			<main>
				<WatsonGlaserDetails
					step={step}
					title={step > 0 ? stepDetails[step - 1].name : ''}
				/>

				{step > 0 && (
					<div className='controls'>
						<Volume volume='50' />
						<Voice />
					</div>
				)}
			</main>
			<footer>
				{step === 0 ? (
					<Button
						variant='primary'
						text='Start Test'
						icon={<IconRight color='#fff' />}
						iconPosition='after'
						action={startAssessment}
					/>
				) : step === 6 ? (
					<>
						<Button
							variant='secondary'
							text='Previous Page'
							icon={<IconLeft />}
							action={previousStep}
						/>
						<Button
							variant='primary'
							text='Finish'
							action={completeAssessment}
						/>
					</>
				) : (
					<>
						<Button
							variant='secondary'
							text='Previous Page'
							icon={<IconLeft />}
							action={previousStep}
						/>
						<Button
							variant='primary'
							text='Next Page'
							icon={<IconRight color='#fff' />}
							iconPosition='after'
							action={nextStep}
						/>
					</>
				)}
			</footer>
		</div>
	);
};

export default WatsonGlaser;

/** @format */

import IconSpeaker from './icons/IconSpeaker';

const Volume = (props) => {
	let volHeight = Math.round(
		(props.volume / 100) * 72
	); /* 72px is the height of the volume control track*/
	return (
		<div className='volume-control'>
			<label htmlFor='volume'>Volume:</label>
			<input
				type='hidden'
				id='volume'
				defaultValue={50}
			/>
			<div className='track'>
				<div
					className='filled-track'
					style={{ height: volHeight + 'px' }}
				></div>
			</div>

			<IconSpeaker />
		</div>
	);
};

export default Volume;

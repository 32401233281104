/** @format */

const LogoVersant = ({ width = 94, height = 24, color = '#ffffff' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 94 24'
			fill='none'
		>
			<path
				d='M92.0272 22.8576C90.5253 22.8576 89.3656 22.4204 88.5481 21.5459C87.7496 20.6715 87.3504 19.4375 87.3504 17.844V9.56584H84.5557V6.97161H85.126C85.8104 6.97161 86.3523 6.75785 86.7515 6.33034C87.1507 5.90282 87.3504 5.33928 87.3504 4.63972V3.35718H90.0024V6.97161H93.453V9.56584H90.0024V17.7566C90.0024 18.2813 90.0785 18.7379 90.2306 19.1266C90.4017 19.5152 90.6774 19.8262 91.0576 20.0593C91.4378 20.2731 91.9416 20.38 92.569 20.38C92.7021 20.38 92.8637 20.3703 93.0538 20.3508C93.2629 20.3314 93.453 20.312 93.6241 20.2925V22.6827C93.377 22.741 93.1013 22.7799 92.7971 22.7993C92.4929 22.8382 92.2363 22.8576 92.0272 22.8576Z'
				fill={color}
			/>
			<path
				d='M70.6648 22.683V6.97185H73.2599V10.0325L72.8321 9.77012C73.2123 8.77907 73.8207 8.01149 74.6572 7.46738C75.5127 6.90384 76.5108 6.62207 77.6515 6.62207C78.7542 6.62207 79.7333 6.87469 80.5888 7.37993C81.4633 7.88518 82.1477 8.58474 82.642 9.47864C83.1553 10.3725 83.412 11.383 83.412 12.5101V22.683H80.7314V13.3846C80.7314 12.5101 80.5793 11.7717 80.2751 11.1693C79.9709 10.5669 79.5336 10.1005 78.9633 9.77012C78.412 9.42034 77.7751 9.24545 77.0526 9.24545C76.3302 9.24545 75.6838 9.42034 75.1135 9.77012C74.5621 10.1005 74.1249 10.5766 73.8017 11.1984C73.4785 11.8008 73.3169 12.5295 73.3169 13.3846V22.683H70.6648Z'
				fill={color}
			/>
			<path
				d='M60.1939 23.0327C59.1863 23.0327 58.2927 22.8481 57.5133 22.4789C56.7528 22.0903 56.154 21.5656 55.7167 20.9049C55.2794 20.2248 55.0608 19.4475 55.0608 18.573C55.0608 17.7374 55.2319 16.9893 55.5741 16.3286C55.9353 15.6484 56.4867 15.0752 57.2281 14.6088C57.9886 14.1424 58.9391 13.8121 60.0798 13.6177L65.7833 12.6558V14.9294L60.6787 15.8039C59.6901 15.9788 58.9676 16.2994 58.5114 16.7658C58.0741 17.2322 57.8555 17.8054 57.8555 18.4856C57.8555 19.1268 58.1026 19.6612 58.5969 20.0887C59.1102 20.5162 59.7471 20.73 60.5076 20.73C61.4772 20.73 62.3137 20.526 63.0171 20.1179C63.7395 19.6904 64.3004 19.1171 64.6996 18.3981C65.1179 17.6791 65.327 16.8824 65.327 16.0079V12.0146C65.327 11.1595 65.0133 10.4697 64.3859 9.94501C63.7775 9.4009 62.9696 9.12885 61.962 9.12885C61.0874 9.12885 60.308 9.36204 59.6235 9.82842C58.9581 10.2754 58.4638 10.8778 58.1406 11.6356L55.8308 10.4114C56.1159 9.69239 56.5722 9.05112 57.1996 8.48758C57.827 7.90461 58.5589 7.44795 59.3954 7.11759C60.2319 6.78724 61.1064 6.62207 62.019 6.62207C63.1977 6.62207 64.2338 6.85526 65.1274 7.32164C66.0209 7.76858 66.7148 8.40014 67.2091 9.2163C67.7224 10.013 67.9791 10.9458 67.9791 12.0146V22.683H65.384V19.7098L65.8688 19.8847C65.5456 20.5065 65.1083 21.0506 64.557 21.517C64.0057 21.9834 63.3593 22.3526 62.6178 22.6247C61.8764 22.8967 61.0684 23.0327 60.1939 23.0327Z'
				fill={color}
			/>
			<path
				d='M48.0533 23.0327C46.5134 23.0327 45.173 22.6441 44.0324 21.8668C42.8917 21.0895 42.0837 20.0402 41.6084 18.7188L43.7187 17.6694C44.1559 18.6022 44.7548 19.3406 45.5153 19.8847C46.2757 20.4288 47.1217 20.7009 48.0533 20.7009C48.8898 20.7009 49.5837 20.4968 50.135 20.0887C50.6864 19.6807 50.962 19.1463 50.962 18.4856C50.962 18.0192 50.829 17.65 50.5628 17.3779C50.3157 17.0864 50.0115 16.863 49.6503 16.7075C49.289 16.5326 48.9563 16.4063 48.6521 16.3286L46.3423 15.6581C44.9544 15.2501 43.9373 14.6671 43.2909 13.9092C42.6635 13.1514 42.3498 12.2672 42.3498 11.2567C42.3498 10.3239 42.578 9.5175 43.0343 8.83736C43.5095 8.1378 44.1464 7.59369 44.9449 7.20504C45.7624 6.81639 46.675 6.62207 47.6826 6.62207C49.0324 6.62207 50.2396 6.97185 51.3042 7.67142C52.3879 8.37099 53.1579 9.35232 53.6141 10.6154L51.4468 11.6356C51.1046 10.8195 50.5913 10.1782 49.9069 9.71182C49.2225 9.22601 48.4525 8.98311 47.597 8.98311C46.8175 8.98311 46.1997 9.18715 45.7434 9.59523C45.2871 9.98388 45.059 10.4794 45.059 11.0818C45.059 11.5288 45.173 11.898 45.4012 12.1895C45.6293 12.4615 45.905 12.6753 46.2282 12.8307C46.5514 12.9668 46.8651 13.0833 47.1693 13.1805L49.6788 13.9384C50.9335 14.3076 51.9031 14.8808 52.5875 15.6581C53.2909 16.4354 53.6427 17.3682 53.6427 18.4564C53.6427 19.3309 53.405 20.1179 52.9297 20.8175C52.4544 21.517 51.7985 22.0611 50.962 22.4498C50.1255 22.8384 49.156 23.0327 48.0533 23.0327Z'
				fill={color}
			/>
			<path
				d='M34.0056 22.6829V6.97177H36.6007V9.85748L36.3155 9.4494C36.6767 8.55551 37.2281 7.89481 37.9695 7.46729C38.711 7.02035 39.614 6.79688 40.6786 6.79688H41.6197V9.36195H40.2794C39.1957 9.36195 38.3212 9.71174 37.6558 10.4113C36.9904 11.0914 36.6577 12.0631 36.6577 13.3262V22.6829H34.0056Z'
				fill={color}
			/>
			<path
				d='M24.9868 23.0327C23.5039 23.0327 22.1826 22.6732 21.0229 21.9542C19.8632 21.2352 18.9506 20.2539 18.2852 19.0102C17.6198 17.7471 17.2871 16.3383 17.2871 14.7837C17.2871 13.2097 17.6103 11.8105 18.2567 10.5863C18.9221 9.36204 19.8156 8.40014 20.9373 7.70057C22.078 6.98157 23.3518 6.62207 24.7586 6.62207C25.8993 6.62207 26.9069 6.83583 27.7814 7.26334C28.675 7.67142 29.4259 8.23496 30.0343 8.95396C30.6617 9.65353 31.137 10.46 31.4602 11.3733C31.8024 12.2672 31.9735 13.1999 31.9735 14.1716C31.9735 14.3853 31.9545 14.6282 31.9164 14.9003C31.8974 15.1529 31.8689 15.3958 31.8309 15.629H19.2263V13.2971H30.2909L29.0362 14.3465C29.2073 13.336 29.1122 12.4324 28.751 11.6356C28.3898 10.8389 27.8575 10.2073 27.1541 9.74097C26.4506 9.27459 25.6522 9.0414 24.7586 9.0414C23.8651 9.0414 23.0476 9.27459 22.3061 9.74097C21.5647 10.2073 20.9848 10.8778 20.5666 11.7522C20.1673 12.6073 20.0057 13.6275 20.0818 14.8128C20.0057 15.9593 20.1769 16.9698 20.5951 17.8443C21.0324 18.6993 21.6407 19.3697 22.4202 19.8555C23.2187 20.3219 24.0837 20.5551 25.0153 20.5551C26.0419 20.5551 26.9069 20.3122 27.6103 19.8264C28.3138 19.3406 28.8841 18.7188 29.3214 17.9609L31.5457 19.1268C31.2415 19.8458 30.7662 20.5065 30.1198 21.1089C29.4925 21.6919 28.7415 22.1583 27.867 22.5081C27.0115 22.8579 26.0514 23.0327 24.9868 23.0327Z'
				fill={color}
			/>
			<path
				d='M7.67112 22.683L0 0.967285H2.9943L9.49623 20.0596H8.89736L15.3993 0.967285H18.3936L10.751 22.683H7.67112Z'
				fill={color}
			/>
		</svg>
	);
};

export default LogoVersant;

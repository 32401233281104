/** @format */

import React, { useEffect, useRef } from 'react';
// import displaySvgIcon from "../../utils/displaySvgIcon";

const InputText = ({
	type,
	label,
	setValue,
	className,
	id,
	name,
	required,
	placeholder,
	defaultValue,
	clearInput,
}) => {
	useEffect(() => {
		//console.log('clearInput', clearInput);
		if (clearInput) {
			setValue(name, '');
		}
	}, [clearInput]);

	return (
		<div className={`input-wrapper ${className}`}>
			<label htmlFor={id}>{label}</label>
			<input
				type={type ? type : 'text'}
				id={id}
				placeholder={placeholder ? placeholder : undefined}
				name={name}
				required={required ? required : false}
				defaultValue={defaultValue}
			/>
		</div>
	);
};

export default InputText;

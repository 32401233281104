import React from 'react';

const Divider = () => {
    return (
        <div style={{
            borderBottom: '1px solid #E1DFEA',
            width: '100%',
        }} />
    );
};

export default Divider;

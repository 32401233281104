/** @format */

import React from 'react';

const IconCaretUp = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill='none'
	>
		<path
			d='M20.296 15.7959C20.1914 15.9008 20.0673 15.984 19.9305 16.0408C19.7938 16.0976 19.6471 16.1268 19.4991 16.1268C19.351 16.1268 19.2044 16.0976 19.0677 16.0408C18.9309 15.984 18.8067 15.9008 18.7022 15.7959L12 9.09376L5.29596 15.7959C5.08461 16.0073 4.79797 16.126 4.49908 16.126C4.2002 16.126 3.91355 16.0073 3.70221 15.7959C3.49086 15.5846 3.37213 15.298 3.37213 14.9991C3.37213 14.7002 3.49086 14.4135 3.70221 14.2022L11.2022 6.70219C11.3067 6.59731 11.4309 6.5141 11.5677 6.45732C11.7044 6.40054 11.851 6.37131 11.9991 6.37131C12.1471 6.37131 12.2938 6.40054 12.4305 6.45732C12.5673 6.5141 12.6914 6.59731 12.796 6.70219L20.296 14.2022C20.4008 14.3067 20.4841 14.4309 20.5408 14.5676C20.5976 14.7044 20.6268 14.851 20.6268 14.9991C20.6268 15.1471 20.5976 15.2937 20.5408 15.4305C20.4841 15.5672 20.4008 15.6914 20.296 15.7959Z'
			fill={color}
		/>
	</svg>
);

export default IconCaretUp;

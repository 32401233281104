export const updateVersantStatusReducer = (state, action) => {
    const { key, status } = action.payload;

    // Check if the key exists in the state and update its status
    if (state[key]) {
        state[key].forEach(item => {
            item.status = status;
        });
    }
}

import React, {useEffect, useState} from "react";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import Button from '../../../components/Button';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../inputs/CreateText";
import SigninButton from "../../inputs/SigninButton";
import { useDispatch, useSelector } from "react-redux";
import { updateCandidate } from "../../../redux/slices/userSlice";
// import { setNavigation } from "../../../redux/slices/navigationSlice";
// import { setUser } from "../../../redux/slices/scenarioSlice";
import { useNavigate } from "react-router";
import CustomLink from "../../outputs/CustomLink"; 
import IconRight from '../../../components/icons/IconRight';
// import { setEmails } from "../../../redux/slices/collegeSlice";
// import {updateTranslationValue, updateGlobalValue} from "../../../redux/slices/scenarioSlice";

const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const candidates = useSelector((state) => state.users.candidates);
  const authenticated = candidates[0].authenticated;
  const {
    register,
    setValue,
    control,
    watch,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    // defaultValues: {
    //   lastName: "",
    // },
  });

  const handleSubmitForm = async (data, event) => {
    // const newContent = "TitleSearchContent";
    // const page = "Start";
    // const path = "/account/info";

    // Prevent the default form submission behavior
    event.preventDefault();
    // Stop the event propagation to prevent it from bubbling up
    event.stopPropagation();
    //console.log("Signin Data:", data);
    const firstName = data.firstName;
    const lastName = data.lastName;
    const email = data.email.toLowerCase();
    const password = data.password;
    data["signed-in"] = true;
    // await dispatch(setUser(data));

    const test = dispatch(updateCandidate({
      firstName,
      lastName,
      email,
      password,
      authenticated: true,
    }));
    //console.log("TEST:", test);
  };

  useEffect(() => {
    //("Authenticated:", authenticated); 
    //console.log("Email:", candidates[0].email.toLowerCase(), "Equal:",candidates[0].email.toLowerCase() === "malik.jones@gmail.com" )
    if (authenticated && candidates[0].email.toLowerCase() === "malik.jones@gmail.com") {
      navigate(`${process.env.PUBLIC_URL}/malik`); // Replace with the route you want to navigate to
    }
  }, [authenticated]); // Only navigates when `authenticated` changes
  
  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="container"
    >
      <div className="header item">
        <h3>Create an account</h3><span className="question-icon">{displaySvgIcon("question-mark")}
        </span>
      </div>
            <Text
            type="text"
              // displayType="segment"
              register={register}
              setValue={setValue}
              className="form-input item"
              id="formGroupFirstName"
              testId="first-name"
              label="First name"
              labelClass="label-element"
              name="firstName"
              required={true}
              placeholder={"First name"}
              // placeholderClass={true? "invalid placeholder-element" : "placeholder-element"}
              placeholderClass="asterick"
              watch={watch}
            />

            <Text
            type="text"
              // displayType="segment"
              register={register}
              setValue={setValue}
              className="form-input item"
              id="formGroupLastName"
              testId="last-name"
              label="Last name"
              labelClass="label-element"
              name="lastName"
              required={true}
              placeholder={"Last name"}
              // placeholderClass={true? "invalid placeholder-element" : "placeholder-element"}
              placeholderClass="asterick"
              watch={watch}
            />
       
       
          <Text
          type="text"
            // displayType="row"
            register={register}
            setValue={setValue}
            className="form-input item"
            id="formGroupEmail"
            testId="email"
            label="School email address"
            labelClass="label-element"
            name="email"
            required={true}
            placeholder="Email"
            // placeholderClass={true? "invalid placeholder-element" : "placeholder-element"}
            placeholderClass="asterick"
            asterickPadding="60px"
            watch={watch}
            />

          <Text
            // displayType="row"
            type="password"
            register={register}
            className="form-input  item"
            id="formGroupPassword"
            testId="password"
            label="Password"
            labelClass="label-element"
            name="password"
            required={true}
            placeholder="Password"
            placeholderClass="asterick"
            asterickPadding="80px"
            watch={watch}
          />
         <div className="row signin-terms item">
         <span className="asterick-style">*</span><span className="page-link sentence-connector"></span>Indicated required field
         </div>
          <Button
        classLabel="item"
        text='Create account'
        variant='tertiary' 
        action={() => console.log('Clicked')}
      />
      <div className="row signin-terms item" style={{justifyContent: 'center'}}>
      By creating an account, you agree to our
        <span
          className="page-link sentence-connector"> Terms of Use</span>
      </div>
      <div className="item" >
        <hr />
      </div>

      <div className="create-account-message item">
        <CustomLink
          classLabel="custom-link have-account"
          newView="create-account"
        // to={`${process.env.PUBLIC_URL}/account/create`}
        >I already have an account <IconRight size={14} /></CustomLink>
      </div>
    </form>
  );
};

export default CreateAccountForm;

/** @format */

import IconCheck from './icons/IconCheck';

const Stepper = (props) => {
	return (
		<ol className={`stepper ${props.variant === 'light' ? 'dark' : ''}`}>
			{props.steps.map((step, i) => {
				return (
					<li
						key={`step${i}`}
						className={`${step.status}`}
					>
						<span className={`step-status`}>
							{step.status === 'completed' ? (
								<IconCheck
									size={16}
									color={`${props.variant === 'light' ? '#fff' : '#0D004D'}`}
								/>
							) : step.status === 'current' ? (
								<svg
									width='25'
									height='24'
									viewBox='0 0 25 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<circle
										cx='12.3335'
										cy='12'
										r='6'
									/>
								</svg>
							) : (
								<></>
							)}
						</span>
						<div className='step-name'>
							{i + 1}. {step.name}
						</div>
					</li>
				);
			})}
		</ol>
	);
};

export default Stepper;

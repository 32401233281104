/** @format */

import React from 'react';

const IconTimer = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M12 4.125C10.22 4.125 8.47991 4.65284 6.99987 5.64177C5.51983 6.63071 4.36628 8.03631 3.68509 9.68085C3.0039 11.3254 2.82567 13.135 3.17294 14.8808C3.5202 16.6266 4.37737 18.2303 5.63604 19.489C6.89472 20.7476 8.49836 21.6048 10.2442 21.9521C11.99 22.2993 13.7996 22.1211 15.4442 21.4399C17.0887 20.7587 18.4943 19.6052 19.4832 18.1251C20.4722 16.6451 21 14.905 21 13.125C20.9973 10.7389 20.0482 8.45129 18.361 6.76405C16.6737 5.07682 14.3861 4.12773 12 4.125ZM12 19.875C10.665 19.875 9.35994 19.4791 8.2499 18.7374C7.13987 17.9957 6.27471 16.9415 5.76382 15.7081C5.25293 14.4747 5.11925 13.1175 5.3797 11.8081C5.64015 10.4988 6.28303 9.29603 7.22703 8.35203C8.17104 7.40803 9.37377 6.76515 10.6831 6.5047C11.9925 6.24425 13.3497 6.37792 14.5831 6.88881C15.8165 7.39971 16.8707 8.26487 17.6124 9.3749C18.3541 10.4849 18.75 11.79 18.75 13.125C18.748 14.9146 18.0362 16.6303 16.7708 17.8958C15.5053 19.1612 13.7896 19.873 12 19.875ZM15.4209 9.32906C15.5258 9.43358 15.609 9.55777 15.6658 9.69452C15.7226 9.83126 15.7518 9.97787 15.7518 10.1259C15.7518 10.274 15.7226 10.4206 15.6658 10.5574C15.609 10.6941 15.5258 10.8183 15.4209 10.9228L12.7959 13.5478C12.5846 13.7592 12.298 13.8779 11.9991 13.8779C11.7002 13.8779 11.4135 13.7592 11.2022 13.5478C10.9908 13.3365 10.8721 13.0498 10.8721 12.7509C10.8721 12.4521 10.9908 12.1654 11.2022 11.9541L13.8272 9.32906C13.9317 9.22418 14.0559 9.14097 14.1926 9.08419C14.3294 9.0274 14.476 8.99818 14.6241 8.99818C14.7721 8.99818 14.9187 9.0274 15.0555 9.08419C15.1922 9.14097 15.3164 9.22418 15.4209 9.32906ZM8.625 1.5C8.625 1.20163 8.74353 0.915483 8.95451 0.704505C9.16549 0.493526 9.45164 0.375 9.75 0.375H14.25C14.5484 0.375 14.8345 0.493526 15.0455 0.704505C15.2565 0.915483 15.375 1.20163 15.375 1.5C15.375 1.79837 15.2565 2.08452 15.0455 2.2955C14.8345 2.50647 14.5484 2.625 14.25 2.625H9.75C9.45164 2.625 9.16549 2.50647 8.95451 2.2955C8.74353 2.08452 8.625 1.79837 8.625 1.5Z' />
	</svg>
);

export default IconTimer;

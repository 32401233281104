/** @format */

import React from 'react';

const IconClock = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill='none'
	>
		<path
			d='M12 1.875C9.99747 1.875 8.0399 2.46882 6.37486 3.58137C4.70981 4.69392 3.41206 6.27523 2.64572 8.12533C1.87939 9.97543 1.67888 12.0112 2.06955 13.9753C2.46023 15.9393 3.42454 17.7435 4.84055 19.1595C6.25656 20.5755 8.06066 21.5398 10.0247 21.9305C11.9888 22.3211 14.0246 22.1206 15.8747 21.3543C17.7248 20.5879 19.3061 19.2902 20.4186 17.6251C21.5312 15.9601 22.125 14.0025 22.125 12C22.122 9.3156 21.0543 6.74199 19.1562 4.84383C17.258 2.94567 14.6844 1.87798 12 1.875ZM12 19.875C10.4425 19.875 8.91993 19.4131 7.62489 18.5478C6.32985 17.6825 5.32049 16.4526 4.72445 15.0136C4.12841 13.5747 3.97246 11.9913 4.27632 10.4637C4.58018 8.93606 5.3302 7.53287 6.43154 6.43153C7.53288 5.3302 8.93607 4.58017 10.4637 4.27632C11.9913 3.97246 13.5747 4.12841 15.0136 4.72445C16.4526 5.32049 17.6825 6.32985 18.5478 7.62488C19.4131 8.91992 19.875 10.4425 19.875 12C19.8728 14.0879 19.0424 16.0896 17.566 17.566C16.0896 19.0424 14.0879 19.8728 12 19.875ZM18.375 12C18.375 12.2984 18.2565 12.5845 18.0455 12.7955C17.8345 13.0065 17.5484 13.125 17.25 13.125H12C11.7016 13.125 11.4155 13.0065 11.2045 12.7955C10.9935 12.5845 10.875 12.2984 10.875 12V6.75C10.875 6.45163 10.9935 6.16548 11.2045 5.9545C11.4155 5.74353 11.7016 5.625 12 5.625C12.2984 5.625 12.5845 5.74353 12.7955 5.9545C13.0065 6.16548 13.125 6.45163 13.125 6.75V10.875H17.25C17.5484 10.875 17.8345 10.9935 18.0455 11.2045C18.2565 11.4155 18.375 11.7016 18.375 12Z'
			fill={color}
		/>
	</svg>
);

export default IconClock;

/** @format */

import { useEffect, useState } from 'react';

const Timer = (props) => {
	const [time, setTime] = useState('00:00:00');

	useEffect(() => {
		let timeInSeconds = props.time * 60;

		let countDown = setInterval(() => {
			timeInSeconds = timeInSeconds - 1;
			let dateObj = new Date(timeInSeconds * 1000);
			let hours = dateObj.getHours();
			let minutes = dateObj.getMinutes();
			let seconds = dateObj.getSeconds();
			let timeString =
				'00' +
				':' +
				minutes.toString().padStart(2, '0') +
				':' +
				seconds.toString().padStart(2, '0');

			//(timeString);

			if (timeInSeconds === 0) {
				clearInterval(countDown);
			}

			setTime((time) => timeString);
		}, 1000);
	}, []);

	return (
		<div className='timer'>
			<small>Time remaining</small> <span className='time'>{time}</span>
		</div>
	);
};

export default Timer;

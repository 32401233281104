import React, { useEffect, useState } from "react";

const CreateText = ({
  type,
  register,
  setValue,
  className,
  id,
  testId,
  labelClass,
  name,
  required,
  placeholder,
  placeholderClass,
  clearInput,
  asterickPadding,
  watch
}) => {

  const [isFocused, setIsFocused] = useState(false);
// Use `watch` to monitor the input's value
const inputValue = watch(name);

  useEffect(() => {
    if (clearInput) {
      setValue(name, "");
    }
  }, [clearInput, name, setValue]);


  return (
    <div className={className}>
      <input
        type={type ? type : "text"}
        aria-labelledby={id}
        {...register(name)}
        className={placeholderClass ? `${placeholderClass}` : ``}
        id={id}
        data-testid={testId}
        placeholder={placeholder ? placeholder : undefined}
        name={name}
        required={required ? required : false}
        onFocus={() => setIsFocused(true)}  // Set focus state
        onBlur={() => setIsFocused(false)}  // Remove focus state
      />
      {!isFocused && !inputValue && (  // Conditionally render the asterisk when focused or input has value
        <span
          style={{
            position: "absolute",
            color: "#df41cf",
            pointerEvents: "none",
            fontSize: "1em",
            zIndex: 1000,
            paddingTop: "8px",
            paddingLeft: asterickPadding ? asterickPadding : "90px"
          }}
        >*</span>
      )}
    </div>
  );
};

export default CreateText;

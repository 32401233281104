/** @format */

import React from 'react';

const IconHeadphones = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M19.1934 4.86093C17.7792 3.43768 15.9743 2.46638 14.0074 2.07014C12.0405 1.67391 10.0003 1.87059 8.1453 2.63525C6.29033 3.39991 4.70416 4.69812 3.58787 6.36531C2.47158 8.0325 1.87543 9.99359 1.875 12V17.25C1.875 17.9462 2.15156 18.6139 2.64384 19.1061C3.13613 19.5984 3.80381 19.875 4.5 19.875H6C6.69619 19.875 7.36387 19.5984 7.85616 19.1061C8.34844 18.6139 8.625 17.9462 8.625 17.25V13.5C8.625 12.8038 8.34844 12.1361 7.85616 11.6438C7.36387 11.1516 6.69619 10.875 6 10.875H4.20375C4.47525 9.00081 5.41264 7.28709 6.84434 6.04753C8.27603 4.80797 10.1063 4.12548 12 4.12499H12.06C13.9453 4.13306 15.7647 4.81965 17.1853 6.05914C18.6059 7.29864 19.5327 9.00817 19.7962 10.875H18C17.3038 10.875 16.6361 11.1516 16.1438 11.6438C15.6516 12.1361 15.375 12.8038 15.375 13.5V17.25C15.375 17.9462 15.6516 18.6139 16.1438 19.1061C16.6361 19.5984 17.3038 19.875 18 19.875H19.5C19.8447 19.875 20.1861 19.8071 20.5045 19.6752C20.823 19.5433 21.1124 19.3499 21.3562 19.1061C21.5999 18.8624 21.7933 18.573 21.9252 18.2545C22.0571 17.9361 22.125 17.5947 22.125 17.25V12C22.1302 10.6757 21.8739 9.36333 21.3708 8.13826C20.8677 6.91318 20.1278 5.79945 19.1934 4.86093ZM6 13.125C6.09946 13.125 6.19484 13.1645 6.26517 13.2348C6.33549 13.3052 6.375 13.4005 6.375 13.5V17.25C6.375 17.3494 6.33549 17.4448 6.26517 17.5152C6.19484 17.5855 6.09946 17.625 6 17.625H4.5C4.40054 17.625 4.30516 17.5855 4.23484 17.5152C4.16451 17.4448 4.125 17.3494 4.125 17.25V13.125H6ZM19.875 17.25C19.875 17.3494 19.8355 17.4448 19.7652 17.5152C19.6948 17.5855 19.5995 17.625 19.5 17.625H18C17.9005 17.625 17.8052 17.5855 17.7348 17.5152C17.6645 17.4448 17.625 17.3494 17.625 17.25V13.5C17.625 13.4005 17.6645 13.3052 17.7348 13.2348C17.8052 13.1645 17.9005 13.125 18 13.125H19.875V17.25Z' />
	</svg>
);

export default IconHeadphones;

/** @format */

const talentLensData = {
	inferItems: [
		{
			statement:
				'The first newspaper in America, edited by Ben Harris, appeared in Boston on September 25, 1690, and was banned the same day by Governor Simon Bradstreet. The editor’s subsequent long fight to continue to publish his paper and print what he wished marks an important episode in the continuing struggle to maintain a free press.',
			proposed:
				'The editor of the first American newspaper died within a few days after his paper was banned on September 25, 1690.',
			choices: [
				'True',
				'Probably True',
				'Insufficient Data',
				'Probably False',
				'False',
			],
		},
	],
	recognizeItems: [
		{
			statement: 'Members who stand to gain the most should pay the most.',
			proposed: 'People gain according to what they pay.',
			choices: ['Assumption made', 'Assumption not made'],
		},
	],
	deduceItems: [
		{
			statement:
				'To be allowed to vote you must be 18 years old and a citizen of the country. Nonresidents eligible to vote can apply for a postal vote. Postal votes must be received 2 weeks before the date of the election. Therefore,...',
			proposed: 'Not all votes need to be cast on the same day.',
			choices: ['Conclusion follows', 'Conclusion does not follow'],
		},
	],
	interpretItems: [
		{
			statement:
				'Recent changes to the curriculum have revised the expectations of what children should achieve when assessed at ages 11 and 16. Overall standards are much higher and some experts have questioned whether expectations are realistic or even attainable. Business leaders have also questioned the workplace relevance of what is taught.',
			proposed:
				'Some argue that children will not achieve what they are expected to achieve under the new curriculum when assessed at age 16.',
			choices: ['Conclusion follows', 'Conclusion does not follow'],
		},
	],
	evaluateArguments: [
		{
			statement:
				'Will removing the television from a child’s life increase that child’s happiness?',
			proposed:
				'No; moderate use of TV provides children with information about the world and models for socializing that enhance their ability to function, and, so, increases their sense of well-being.',
			choices: ['Argument true', 'Argument weak'],
		},
	],
};

export default talentLensData;

/** @format */

import { useState } from 'react';
import IconCaretDown from './icons/IconCaretDown';

const Accordion = (props) => {
	const [isOpen, setisOpen] = useState(false);
	return (
		<div className={`accordion ${isOpen ? 'open' : ''}`}>
			<div
				className='accordion-title'
				role='button'
				onClick={() => setisOpen(!isOpen)}
			>
				<p>{props.question}</p>

				<IconCaretDown size={16} />
			</div>
			<div className='accordion-body'>
				<p>{props.answer}</p>
			</div>
		</div>
	);
};

export default Accordion;

import React from 'react';
import { useDispatch } from 'react-redux';

const CustomLink = ({ children, newView, classLabel }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior

    // dispatch(updateGlobalValue({key: 'current-view', value: newView}));
  };

  return (
    <a
      className={classLabel}
      onClick={handleClick}
    // style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
    //   {...rest}
    >
      {children}
    </a>
  );
};

export default CustomLink;
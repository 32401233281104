/** @format */

import React, { useState } from 'react';

function Checbox(props) {
	const [selectedOption, setSelectedOption] = useState(null);

	const handleRadio = (event) => {
		setSelectedOption(event.target.value);
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<div className='checkbox-container'>
			<input
				type='checkbox'
				id={props.id}
				defaultChecked={`${props.checked ? 'checked' : ''}`}
			/>
			<label htmlFor={props.id}>{props.label}</label>
		</div>
	);
}

export default Checbox;

import React, { useEffect, useState } from "react";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import Button from '../../../components/Button';
import { set, useForm } from "react-hook-form";
import Text from "../../inputs/Text";
import SigninButton from "../../inputs/SigninButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomLink from "../../outputs/CustomLink";
import IconRight from '../../../components/icons/IconRight';
import {updateRecruiterAuth} from "../../../redux/slices/userSlice";

const SigninFormContent = ({ flow }) => {
  const dispatch = useDispatch();

  const recruiter = useSelector((state) => state.users.recruiter);
  const candidates = useSelector((state) => state.users.candidates);
  const jobProfiles = useSelector((state) => state.users.jobProfiles);

  const [recruiterEmail, setRecruiterEmail] = useState("");
  const [recruiterPassword, setRecruiterPassword] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePassword, setCandidatePassword] = useState("");
  const [candidateSignedIn, setCandidateSignedIn] = useState(false);
  const [recruiterSignedIn, setRecruiterSignedIn] = useState(false);
  const [idInvalid, setIdInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordInvalidMsg, setpasswordInvalidMsg] = useState("Password");
  const [idInvalidMsg, setidInvalidMsg] = useState("UserName");

  const navigate = useNavigate();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      // lastName: {
      //   value: "new",
      //   label: "New",
      // },
    },
  });

  const handleSubmitForm = async (data, event) => {
    localStorage.clear(); //Remove all localstorage from this domain.
    // Prevent the default form submission behavior
    event.preventDefault();
    // Stop the event propagation to prevent it from bubbling up
    event.stopPropagation();

    // console.log("Signin Data:", data);
    // console.log("candidateEmail:", candidateEmail, "CandidatePassword:", candidatePassword);
    // console.log("recruiterEmail:", recruiterEmail, "recruiterPassword:", recruiterPassword);
    // console.log("EMAIL Equal:", data?.email === recruiterEmail, "Password Equal:", data?.password === recruiterPassword)
    if (data?.email.toLowerCase() === recruiterEmail.toLowerCase()) {
      setIdInvalid(false);
      if (data?.password === recruiterPassword) {
        setRecruiterSignedIn(true)
      }
      else {
        setRecruiterSignedIn(false);
      }
    }
    else if (data?.email.toLowerCase() === candidateEmail.toLowerCase()) {
      setIdInvalid(false);
      // console.log("CANDIDATE:", data, "candidateEmail:", candidateEmail, "CandidatePassword:", candidatePassword);
      if (data?.password === candidatePassword) {
        setCandidateSignedIn(true)
      }
      else {
        setCandidateSignedIn(false);
      }
    }
    else {
      setIdInvalid(true);
      setidInvalidMsg("Username Invalid")
    }
    if (data?.password !== candidatePassword || data?.password !== recruiterPassword) {
      setPasswordInvalid(true);
      setpasswordInvalidMsg("Password Invalid");
    }
    else {
      setPasswordInvalid(false);
    }
  };

  // Use useEffect to log the data after the component renders
  useEffect(() => {
    // console.log('CANDIDATES:', candidates);
    // console.log('RECRUITER:', recruiter);
    // console.log('JOB_PROFILES:', jobProfiles);
    if (candidates.length > 0) {
      setCandidateEmail(candidates[0].email);
      setCandidatePassword(candidates[0].password);
    }
    setRecruiterEmail(recruiter.email);
    setRecruiterPassword(recruiter.password);
  }, [candidates, recruiter, jobProfiles]); // Dependency array includes the data you want to log

  useEffect(() => {
    if (candidateSignedIn) {
      navigate(`${process.env.PUBLIC_URL}/malik`);
      //console.log("Signed in successfully");
    }
    else if (recruiterSignedIn) {
      navigate(`${process.env.PUBLIC_URL}/georgia`);
      //console.log("Signed in successfully");
      dispatch(updateRecruiterAuth({ authenticated: true }));
    }
    else {
      //console.log("Sign in failed");
    }
  }, [candidateSignedIn, recruiterSignedIn]);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="container"
    >
      <div className="header item">
        <h3>Sign in</h3><span className="question-icon">{displaySvgIcon("question-mark")}
        </span>
      </div>
      <Text
        type="text"
        register={register}
        setValue={setValue}
        className="form-input item"
        id="formGroupEmail"
        testId="login-email"
        labelClass="label-element"
        name="email"
        required={true}
        placeholder={idInvalidMsg}
        placeholderClass={idInvalid ? "invalid placeholder-element" : "placeholder-element"}
        clearInput={idInvalid}
      />
      <Text
        type="password"
        register={register}
        setValue={setValue}
        className="form-input item"
        id="formGroupPassword"
        testId="login-password"
        labelClass="label-element"
        name="password"
        required={true}
        placeholder={passwordInvalidMsg}
        placeholderClass={passwordInvalid ? "invalid placeholder-element" : "placeholder-element"}
        clearInput={passwordInvalid}
      />
      <p className="reset-message item"><span className="page-link">Forgot your username or password?</span></p>

      <Button
        classLabel="item"
        text='Sign in'
        variant='tertiary'
        action={() => console.log('Clicked')}
      />
      <div className="row signin-terms item">
        By signing in, you agree to our
        <span
          className="page-link sentence-connector"> Terms of use.</span>
      </div>

      <div className="item" >
        <hr />
      </div>

      <div className="create-account-message item">
        <CustomLink
          classLabel="custom-link create-account"
          newView="create-account"
        // to={`${process.env.PUBLIC_URL}/account/create`}
        >Create an account<IconRight size={14} /></CustomLink>
      </div>
    </form>
  );
};

export default SigninFormContent;

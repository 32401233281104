/** @format */

const versantData = {
	readingItems: [
		{
			instructions: 'Please read the sentences as instructed.',
			sentences: [
				'Buying bicycle parts without any expertise can be difficult.',
				'Products can range widely in price and yet not be very different.',
				'Even worse, some accessories are useless and could even be dangerous.',
				'Bike shop sales assistants are not always helpful to the average riders.',
				'Living in a harsh climate has benefits and drawbacks.',
				'In some years, the trees can turn beautiful colors for a whole month.',
				'Snow can be fun for skiing or just to play in.',
				'But driving around in the ice or walking in the cold creates difficulties.',
			],
			status: '',
		},
	],
	repeatItems: [
		{
			instructions: 'Please repeat each sentence that you hear.',
			questions: [],
			status: '',
		},
	],
	sentenceCompletionItems: [
		{
			instructions:
				'Please type one word that best fits the meaning of the sentence. Type only one word. You will have 25 seconds for each sentence. Click ‘Next’ when you are finished.',
			items: [
				{
					sentence: 'I haven’t eaten anything all day. I’m so ',
					answer: 'hungry',
				},
			],
			status: '',
		},
	],
	passageReconstructionItems: [
		{
			instructions:
				'You will have 30 seconds to read a paragraph. Then, you will have 90 seconds to reconstruct the paragraph.',
			status: '',
		},
	],
	openQuestionItems: [
		{
			instructions:
				'You will hear a question, and then you will have 40 seconds to answer the question.',
			status: '',
		},
	],
};

export default versantData;

import React from 'react';

const ProgressBar = ({ totalSteps, completedSteps }) => {

    const progress = (completedSteps / totalSteps) * 100;

    return (
        <div className="progress-bar">
            {[...Array(totalSteps)].map((_, index) => (
                <div
                    key={index}
                    className={`progress-step ${completedSteps > index ? 'completed' : ''}`}
                />
            ))}
            <div
                className="progress-fill"
                style={{ width: `${progress}%` }}
            />
            <div className="progress-segment first-step" />
            <div className="progress-segment second-step" />
        </div>
    );
};

export default ProgressBar;
/** @format */

import React from 'react';

const IconLeft = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox='0 0 24 24'
		fill={color}
	>
		<path d='M15.796 18.704C16.0073 18.9154 16.1261 19.202 16.1261 19.5009C16.1261 19.7998 16.0073 20.0864 15.796 20.2978C15.5846 20.5091 15.298 20.6279 14.9991 20.6279C14.7002 20.6279 14.4136 20.5091 14.2022 20.2978L6.70222 12.7978C6.59734 12.6933 6.51413 12.5691 6.45735 12.4323C6.40057 12.2956 6.37134 12.149 6.37134 12.0009C6.37134 11.8528 6.40057 11.7062 6.45735 11.5695C6.51413 11.4327 6.59734 11.3086 6.70222 11.204L14.2022 3.70404C14.4136 3.49269 14.7002 3.37396 14.9991 3.37396C15.298 3.37396 15.5846 3.49269 15.796 3.70404C16.0073 3.91538 16.1261 4.20203 16.1261 4.50091C16.1261 4.7998 16.0073 5.08644 15.796 5.29779L9.09379 12L15.796 18.704Z' />
	</svg>
);

export default IconLeft;

/** @format */
import Button from './Button';
import PearsonLogo from './PearsonLogo';
import Avatar from './Avatar';
import Stepper from './Stepper';
import Timer from './Timer';
import { useDispatch, useSelector } from 'react-redux';
import LogoVersantNoWave from './icons/LogoVersantNoWave';
import LogoWatsonGlaser from './icons/LogoWatsonGlaser';
import LogoDAT from './icons/LogoDAT';

const Header = (props) => {
	const candidates = useSelector((state) => state.users.candidates);
	const recruiter = useSelector((state) => state.users.recruiter);
	const recruiterAuthenticated = recruiter.authenticated;
	const candidateAuthenticated = candidates[0].authenticated;
	return (
		<header className={`app-header ${props.variant}`}>
			<a href='!#'>
				{props.logo == 'versant' ? (
					<LogoVersantNoWave
						color={`${props.variant === 'light' ? '#512EAB' : '#fff'}`}
						width={94}
						height={22}
					/>
				) : props.logo === 'watson' ? (
					<LogoWatsonGlaser
							color={`${props.variant === 'light' ? '#512EAB' : '#fff'}`}
							width={204}
							height={22}
					/>
				) : props.logo === 'DAT' ? (
					<LogoDAT
						color={`${props.variant === 'light' ? '#512EAB' : '#fff'}`}
					/>
				) : (
					<>
						<PearsonLogo
							color={`${props.variant === 'light' ? 'dark' : 'light'}`}
						/>
						{props.fullLogo ? (
							<span className='logo-text'>
								360&deg; Talent Assessment Solutions
							</span>
						) : (
							<></>
						)}
					</>
				)}
			</a>
			{props.steps ? (
				<Stepper
					steps={props.steps}
					variant={props.variant}
				/>
			) : (
				<></>
			)}
			<div className='right'>
				{props.action ? (
					<Button
						variant={`${
							props.variant === 'light' ? 'secondary' : 'secondary-inverse'
						}`}
						size='small'
						text={props.action}
						action={props.btnAction}
					/>
				) : (
					<></>
				)}
				{props.time ? <Timer time={'1'} /> : <></>}
				{!candidateAuthenticated && !recruiterAuthenticated ? (
					<Avatar />
				) : (
					<Avatar userType='female' />
				)}
			</div>
		</header>
	);
};

export default Header;

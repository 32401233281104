/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
	getRecruiter,
	getCandidates,
	getJobProfiles,
} from '../redux/slices/userSlice';
import Header from '../components/Header';
import Button from '../components/Button';
import IconLeft from '../components/icons/IconLeft';
import IconRight from '../components/icons/IconRight';
import IconCheck from '../components/icons/IconCheck';
import IconX from '../components/icons/IconX';
import IconDoor from '../components/icons/IconDoor';
import IconExam from '../components/icons/IconExam';
import IconHeadphones from '../components/icons/IconHeadphones';
import IconPlaceholder from '../components/icons/IconPlaceholder';
import IconSpeaker from '../components/icons/IconSpeaker';
import IconSquares from '../components/icons/IconSquares';
import IconTimer from '../components/icons/IconTimer';
import IconMic from '../components/icons/IconMic';
import Avatar from '../components/Avatar';
import Radio from '../components/Radio';
import Sidebar from '../components/Sidebar';
import Volume from '../components/Volume';
import IconArrowLeft from '../components/icons/IconArrowLeft';
import IconCaretUp from '../components/icons/IconCaretUp';
import IconCaretDown from '../components/icons/IconCaretDown';
import IconClock from '../components/icons/IconClock';
import IconItems from '../components/icons/IconItems';
import IconLocked from '../components/icons/IconLocked';
import IconPencil from '../components/icons/IconPencil';
import IconSquaresFilled from '../components/icons/IconSquaresFilled';
import IconTemplate from '../components/icons/IconTemplates';
import IconUnlocked from '../components/icons/IconUnlocked';
import IconQuestions from '../components/icons/IconQuestions';
import IconQuestion from '../components/icons/IconQuestion';
import Timer from '../components/Timer';
import Checkbox from '../components/Checkbox';
import Voice from '../components/Voice';

const GenericTemplate = () => {
	// Using selectors to access the recruiter and candidates from the state
	const recruiter = useSelector((state) => state.users.recruiter);
	const candidates = useSelector((state) => state.users.candidates);
	const jobProfiles = useSelector((state) => state.users.jobProfiles);
	const readingItems = useSelector((state) => state.versant.readingItems);
	const inferItems = useSelector((state) => state.talentLens.inferItems);
	// Reducers
	// const recruiter = useSelector(getRecruiter);
	// const candidates = useSelector(getCandidates);
	// const jobProfiles = useSelector(getJobProfiles);

	// Use useEffect to log the data after the component renders
	useEffect(() => {
		/*console.log('CANDIDATES:', candidates);
		console.log('RECRUITER:', recruiter);
		console.log('JOB_PROFILES:', jobProfiles);
		console.log('READING_ITEMS:', readingItems);
		console.log('INFER_ITEMS:', inferItems); */
	}, [candidates, recruiter, jobProfiles, readingItems, inferItems]); // Dependency array includes the data you want to log

	//Handle Radio
	const radioOptions = ['True', 'False'];
	const radioSelectionChange = (value) => {
		console.log('radio option select :', value);
	};

	/* Steps for stepper (in header) */
	const steps = [
		{
			name: 'Infer',
			status: 'completed',
		},
		{
			name: 'Recognize',
			status: 'current',
		},
		{
			name: 'Deduce',
			status: '',
		},
		{
			name: 'Interpret',
			status: '',
		},
		{
			name: 'Evaluate',
			status: '',
		},
		{
			name: 'Finish',
			status: '',
		},
	];

	return (
		<div className='page-wrapper'>
			<Header
				steps={steps}
				variant='dark' /* dark/light/gradient */
			/>
			<Sidebar />
			<main>
				<h2>Button examples</h2>
				<br />
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Button
						text='button'
						variant='primary'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						variant='secondary'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						variant='tertiary'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						disabled='true'
						action={() => console.log('Clicked')}
					/>
				</div>
				<br />
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Button
						text='button'
						variant='primary'
						size='small'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						variant='secondary'
						size='small'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						variant='tertiary'
						size='small'
						action={() => console.log('Clicked')}
					/>
					<Button
						text='button'
						size='small'
						disabled='true'
						action={() => console.log('Clicked')}
					/>
				</div>

				<br />
				<h2>Icon examples</h2>
				<br />
				<div>
					<IconArrowLeft size={32} />
					<IconCaretDown size={32} />
					<IconCaretUp size={32} />
					<IconCheck
						size={28}
						color='green'
					/>
					<IconClock size={32} />
					<IconDoor
						size={32}
						color='brown'
					/>
					<IconExam
						size={30}
						color='blue'
					/>
					<IconHeadphones
						size={32}
						color='orange'
					/>
					<IconItems size={32} />
					<IconLeft
						size={32}
						color='grey'
					/>
					<IconLocked size={32} />
					<IconMic size={32} />
					<IconPencil size={32} />
					<IconPlaceholder
						size={32}
						color='lightgrey'
					/>
					<IconQuestion size={32} />
					<IconQuestions size={32} />
					<IconRight
						size={32}
						color='grey'
					/>
					<IconSpeaker
						size={32}
						color='skyblue'
					/>
					<IconSquares
						size={32}
						color='#cccccc'
					/>
					<IconSquaresFilled size={32} />
					<IconTemplate size={32} />
					<IconTimer size={32} />
					<IconUnlocked size={32} />
					<IconX
						size={32}
						color='red'
					/>
				</div>

				<h2>Avatar examples</h2>
				<div>
					<Avatar userType='female' />
					<Avatar />
				</div>

				<br />
				<h2>Radio example</h2>
				<br />
				<Radio
					options={radioOptions}
					onChange={radioSelectionChange}
				/>
				<br />

				<h2>checkbox</h2>
				<div>
					<Checkbox
						checked='true'
						label='Checkbox label 1'
						id='checkbox1'
					/>
					<Checkbox
						checked='false'
						label='Checkbox label 2'
						id='checkbox2'
					/>
				</div>
				<a href='!#'>Link style</a>
				<br />
				<h2>Timer</h2>
				<Timer time='1' />
				<br />
				<h2>Volume & Voice</h2>
				<div style={{ background: '#ccc', padding: '1rem' }}>
					<Volume volume='50' />

					<Voice />
				</div>
			</main>
		</div>
	);
};

export default GenericTemplate;

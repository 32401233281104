/** @format */

const LogoPearson = ({ width = 224, height = 52, color = '#FFFFFF' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
		>
			<path
				d='M0.0262178 0H16.8721C26.7106 0 33.2929 6.97202 33.2929 16.2712C33.2929 25.5704 26.7106 32.5424 16.8721 32.5424H8.51958V50.8463H0.0249023V0H0.0262178ZM24.6587 16.2712C24.6587 11.4778 21.6143 8.28079 15.8099 8.28079H8.51958V24.2616H15.8099C21.6143 24.2616 24.6587 21.0646 24.6587 16.2712Z'
				fill={color}
			/>
			<path
				d='M129.826 39.3672C129.826 39.3672 134.287 44.3065 139.948 44.3065C143.417 44.3065 146.319 43.2908 146.319 41.1824C146.319 38.9309 144.762 38.4947 139.666 37.4047C133.011 35.9527 127.207 33.0475 127.207 26.509C127.207 21.1335 132.445 15.7593 141.648 15.7593C149.221 15.7593 154.248 19.9719 154.248 19.9719L150.002 26.3645C150.002 26.3645 146.181 23.3864 141.649 23.3864C138.465 23.3864 135.563 24.5493 135.563 26.6549C135.563 28.7606 137.828 29.2698 143.491 30.5056C150.144 31.9575 154.886 34.2104 154.886 41.3283C154.886 47.2117 148.8 51.9336 140.234 51.9336C130.396 51.9336 125.086 45.6882 125.086 45.6882L129.829 39.3685H129.826V39.3672Z'
				fill={color}
			/>
			<path
				d='M191.651 33.8445C191.651 43.9406 183.724 51.931 173.743 51.931C163.762 51.931 155.835 43.9406 155.835 33.8445C155.835 23.7485 163.762 15.7581 173.743 15.7581C183.724 15.7581 191.651 23.6755 191.651 33.8445ZM183.017 33.8445C183.017 28.469 179.478 23.893 173.743 23.893C168.008 23.893 164.47 28.469 164.47 33.8445C164.47 39.2201 168.008 43.7961 173.743 43.7961C179.478 43.7961 183.017 39.2201 183.017 33.8445Z'
				fill={color}
			/>
			<path
				d='M102.252 33.6297C102.252 33.5486 102.247 33.4689 102.247 33.3879C102.247 33.3879 102.247 33.1934 102.241 33.1934C102.019 23.2351 94.1809 15.5432 84.3451 15.5432C74.5092 15.5432 66.437 23.4607 66.437 33.6297C66.437 38.9404 68.743 44.0539 72.7218 47.4575C77.1403 51.2366 84.0002 53.2059 89.3768 50.4411C91.0168 49.5983 92.7094 48.2477 93.6176 46.5661V50.845H102.247V33.8134C102.247 33.7512 102.252 33.6918 102.252 33.631V33.6297ZM84.3437 43.5826C78.6104 43.5826 75.0699 39.0066 75.0699 33.631C75.0699 28.2555 78.6078 23.6795 84.3437 23.6795C90.0797 23.6795 93.6163 28.2555 93.6163 33.631C93.6163 39.0066 90.0784 43.5826 84.3437 43.5826Z'
				fill={color}
			/>
			<path
				d='M128.122 16.6344L125.148 24.4789C125.148 24.4789 123.59 23.6794 121.113 23.6794C115.945 23.6794 113.612 28.3283 113.612 28.3283V50.8462H105.117V16.4156H113.187V21.4278C113.187 21.4278 116.089 15.5444 122.317 15.5444C125.361 15.5444 128.122 16.6331 128.122 16.6331V16.6344Z'
				fill={color}
			/>
			<path
				d='M223.942 30.3557C223.942 21.7278 217.126 14.7341 208.719 14.7341C200.313 14.7341 193.495 21.7278 193.495 30.3557C193.495 30.8568 193.573 51.061 193.573 51.061H202.067C202.067 51.061 202.081 34.3954 202.095 32.6166C202.108 31.0337 202.002 29.1698 202.632 27.7152C203.707 25.2381 206.052 23.5687 208.721 23.5687C210.715 23.5687 212.559 24.506 213.835 26.0579C215.266 27.7948 215.341 30.0707 215.337 32.2466C215.324 38.523 215.375 44.7886 215.375 51.0597H223.869C223.869 51.0597 223.946 30.8554 223.946 30.3543H223.942V30.3557Z'
				fill={color}
			/>
			<path
				d='M55.3071 40.464C53.9606 42.3049 51.3177 43.7987 47.8983 43.7987C42.4479 43.7987 39.9709 39.8751 39.6866 36.8267H64.5915C64.7376 35.8448 64.8166 34.8264 64.8166 33.7756C64.8166 33.1219 64.8166 31.8144 64.7455 30.507C63.6057 21.3118 56.3337 15.5378 47.8996 15.5378C38.2032 15.5378 31.055 23.5282 31.0537 33.6243C31.0537 37.6708 32.6121 41.3203 32.6081 41.4175C35.2181 47.5427 41.0001 51.7148 48.0075 51.7148C55.0149 51.7148 61.5511 47.3387 64.019 40.4626H55.3097L55.3071 40.464ZM47.8127 23.3351C52.2917 23.3351 55.9218 26.397 55.9218 30.1734H39.7063C39.7063 26.3957 43.3364 23.3351 47.8127 23.3351Z'
				fill={color}
			/>
		</svg>
	);
};

export default LogoPearson;

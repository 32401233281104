/** @format */

import React from 'react';

const IconCaretDown = ({ size = 24, color = '#0D004D' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={size}
		height={size}
		viewBox={`0 0 24 24`}
		fill='none'
	>
		<path
			d='M20.296 9.79592L12.796 17.2959C12.6914 17.4008 12.5673 17.484 12.4305 17.5408C12.2938 17.5976 12.1471 17.6268 11.9991 17.6268C11.851 17.6268 11.7044 17.5976 11.5677 17.5408C11.4309 17.484 11.3067 17.4008 11.2022 17.2959L3.70221 9.79592C3.49086 9.58457 3.37213 9.29793 3.37213 8.99904C3.37213 8.70016 3.49086 8.41351 3.70221 8.20217C3.91355 7.99082 4.2002 7.87209 4.49908 7.87209C4.79797 7.87209 5.08461 7.99082 5.29596 8.20217L12 14.9062L18.7041 8.20123C18.9154 7.98989 19.2021 7.87115 19.501 7.87115C19.7998 7.87115 20.0865 7.98989 20.2978 8.20123C20.5092 8.41258 20.6279 8.69922 20.6279 8.99811C20.6279 9.29699 20.5092 9.58364 20.2978 9.79498L20.296 9.79592Z'
			fill={color}
		/>
	</svg>
);

export default IconCaretDown;
